import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Box, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Page from '../../../components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import data from './data';
import { getAutoCompletedata } from '../../../utils/CommonGraphql';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = (props) => {
  const classes = useStyles();
  const [customers] = useState(data);

  const ContractorData = useSelector(state => state.ContractorData);
  //console.log("counter", ReduxState.Contractor.ContractorId)
  

  const DataString = {
    GetData: {
      pk: ContractorData.ContractorId,
      sk: "Data::Setup",
      DbIndex: "pk1"
    },
    FindArray: [
      {
        ArrayName: "Esc",
        ArraySearchString: "Data::Setup::Esc",
      },
      {
        ArrayName: "Form",
        ArraySearchString: "Data::Setup::Form",
      },
      {
        ArrayName: "Page",
        ArraySearchString: "Data::Setup::Page",
      }
    ]
  }

  useEffect(() => {
    DataBack(DataString);
  }, [])

  const DataBack = async (d) => {
    let NewData = await getAutoCompletedata(d);
    
  }

  return (
    <Page
      className={classes.root}
      title="Customers"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results customers={customers} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
