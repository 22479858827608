// eslint-disable
// this is an auto generated file. This will be overwritten

export const getSvcbotDb = `query GetSvcbotDb($pk1: String!, $sk1: String) {
  getSvcbotDb(pk1: $pk1, sk1: $sk1) {
    gsi1Pk2
    gsi1Sk2
    gsi2Pk2
    gsi2Sk2
    pk1
    sk1
    dataJSON
  }
}
`;

export const batchGet = `query BatchGet($list: [CreateSvcbotDbInput]) {
  batchGet(list: $list) {
    gsi1Pk2
    gsi1Sk2
    gsi2Pk2
    gsi2Sk2
    pk1
    sk1
    dataJSON
  }
}
`;

// search pk1 and sk1 = begains with
export const CustomerPost = `query customerPost(
  $after: String
  $first: Int
  $filter: TableSvcbotDbFilterInput
  $pk1: String!
  $startdate: String
  $enddate: String
) {
  CustomerPost(
    after: $after
    first: $first
    pk1: $pk1
    startdate: $startdate
    enddate: $enddate
    filter: $filter

  ) {
    items {
      gsi1Pk2
      gsi1Sk2
      gsi2Pk2
      gsi2Sk2
      pk1
      sk1
      dataJSON
    }
    nextToken
  }
}
`;

export const ContractorPost = `query contractorPost(
  $after: String
  $first: Int
  $filter: TableSvcbotDbFilterInput
  $gsi2Pk2: String!
  $startdate: String
  $enddate: String
) {
  ContractorPost(
    after: $after
    first: $first
    gsi2Pk2: $gsi2Pk2
    startdate: $startdate
    enddate: $enddate
    filter: $filter

  ) {
    items {
      gsi1Pk2
      gsi1Sk2
      gsi2Pk2
      gsi2Sk2
      pk1
      sk1
      dataJSON
    }
    nextToken
  }
}
`;

//repurpose MiddlePost for Post Id dates
export const MiddlePost = `query middlePost(
  $after: String
  $first: Int
  $filter: TableSvcbotDbFilterInput
  $gsi1Pk2: String!
  $startdate: String
  $enddate: String
) {
  MiddlePost(
    after: $after
    first: $first
    gsi1Pk2: $gsi1Pk2
    startdate: $startdate
    enddate: $enddate
    filter: $filter

  ) {
    items {
      gsi1Pk2
      gsi1Sk2
      gsi2Pk2
      gsi2Sk2
      pk1
      sk1
      dataJSON
    }
    nextToken
  }
}
`;

//repurpose MiddleTeam to find teams active on dates
export const MiddleTeam = `query middleTeam(
  $after: String
  $first: Int
  $filter: TableSvcbotDbFilterInput
  $gsi1Pk2: String!
  $enddate: String
) {
  MiddleTeam(
    after: $after
    first: $first
    gsi1Pk2: $gsi1Pk2
    enddate: $enddate
    filter: $filter

  ) {
    items {
      gsi1Pk2
      gsi1Sk2
      gsi2Pk2
      gsi2Sk2
      pk1
      sk1
      dataJSON
    }
    nextToken
  }
}
`;


// search pk1 and sk1 = begains with
export const PrimaryKeyData = `query primaryKeyData(
  $after: String
  $first: Int
  $pk1: String!
  $sk1: String
) {
  PrimaryKeyData(
    after: $after
    first: $first
    pk1: $pk1
    sk1: $sk1
  ) {
    items {
      gsi1Pk2
      gsi1Sk2
      gsi2Pk2
      gsi2Sk2
      pk1
      sk1
      dataJSON
    }
    nextToken
  }
}
`;

//search gsi2Pk2 and gsi2Sk2 = begains with
export const ContractorDataPerCategory = `query contractorDataPerCategory(
  $after: String
  $first: Int
  $gsi2Pk2: String!
  $gsi2Sk2: String
) {
  ContractorDataPerCategory(
    after: $after
    first: $first
    gsi2Pk2: $gsi2Pk2
    gsi2Sk2: $gsi2Sk2
  ) {
    items {
      gsi1Pk2
      gsi1Sk2
      gsi2Pk2
      gsi2Sk2
      pk1
      sk1
      dataJSON
    }
    nextToken
  }
}
`;

//search gsi1Pk2 and gsi1Sk2 = begains with
export const CompanyDataPerCategory = `query companyDataPerCategory(
  $after: String
  $first: Int
  $gsi1Pk2: String!
  $gsi1Sk2: String
) {
  CompanyDataPerCategory(
    after: $after
    first: $first
    gsi1Pk2: $gsi1Pk2
    gsi1Sk2: $gsi1Sk2
  ) {
    items {
      gsi1Pk2
      gsi1Sk2
      gsi2Pk2
      gsi2Sk2
      pk1
      sk1
      dataJSON
    }
    nextToken
  }
}
`;

export const getFullCompanyInfo = `query petFullCompanyInfo(
  $after: String
  $first: Int
  $pk1: String!
) {
  getFullCompanyInfo(
    after: $after
    first: $first
    pk1: $pk1
  ) {
     items {
      gsi1Pk2
      gsi1Sk2
      gsi2Pk2
      gsi2Sk2
      pk1
      sk1
      dataJSON
    }
   nextToken
   }
}
`;

export const getPeopleInCompany = `query getPeopleInCompany($pk1: String!) {
  getPeopleInCompany(pk1: $pk1) {
    gsi1Sk2
    gsi2Sk2
    dataJSON
  }
}
`;

export const listSvcbotDbs = `query ListSvcbotDbs(
  $filter: TableSvcbotDbFilterInput
  $limit: Int
  $nextToken: String
) {
  listSvcbotDbs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      gsi1Pk2
      gsi1Sk2
      gsi2Pk2
      gsi2Sk2
      pk1
      sk1
    }
    nextToken
  }
}
`;

export const querySvcbotDbsByGsi1Pk2Gsi1Sk2Index = `query QuerySvcbotDbsByGsi1Pk2Gsi1Sk2Index(
  $after: String
  $first: Int
  $gsi1Pk2: String!
) {
  querySvcbotDbsByGsi1Pk2Gsi1Sk2Index(
    after: $after
    first: $first
    gsi1Pk2: $gsi1Pk2
  ) {
    items {
      gsi1Pk2
      gsi1Sk2
      gsi2Pk2
      gsi2Sk2
      pk1
      sk1
      dataJSON
    }
    nextToken
  }
}
`;
export const querySvcbotDbsByGsi2Pk2Gsi2Sk2Index = `query QuerySvcbotDbsByGsi2Pk2Gsi2Sk2Index(
  $after: String
  $first: Int
  $gsi2Pk2: String!
) {
  querySvcbotDbsByGsi2Pk2Gsi2Sk2Index(
    after: $after
    first: $first
    gsi2Pk2: $gsi2Pk2
  ) {
    items {
      gsi1Pk2
      gsi1Sk2
      gsi2Pk2
      gsi2Sk2
      pk1
      sk1
    }
    nextToken
  }
}
`;
export const searchSoedbs = `query SearchSoedbs(
  $filter: SearchablesoedbFilterInput
  $sort: SearchablesoedbSortInput
  $limit: Int
  $nextToken: Int
) {
  searchSoedbs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      gsi1Pk2
      gsi1Sk2
      gsi2Pk2
      gsi2Sk2
      pk1
      sk1
    }
    nextToken
  }
}
`;
