/*
 * 
 * List all the progress steps in a card
 * add button to edit current list
 * 
 * 
 */


import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
//import { removeEmptyStringElements, NewPageId } from '../utils/CommonGraphql';
//import { currentTime } from '../utils/DateTime';
import { useSelector } from 'react-redux';
import Accordion from '../components/Accordion';
import {
  TextField,
  Collapse,
  Paper, Grid, Button,
  Backdrop,
  CircularProgress,
  Card, FormGroup,
  CardContent,
  CardActions,
  InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
  RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
  Switch, Tooltip, Divider
} from '@mui/material';
import {
  removeEmptyStringElements,
  NewPageId,
  EleminateDuplicateObjects,
  getAutoCompletedata
} from '../utils/CommonGraphql';
//import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//import ExpandLessIcon from '@mui/icons-material/ExpandLess';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerShow from '../utils/DrawerModule';
//import RenderModule from '../utils/RenderModule';
import ShowCheckBoxGroup from '../components/ShowCheckBoxGroup';
import ProgressStepper from '../Setup/ProgressStepper'
import { ProgressQuestions } from '../utils/UrgencyOptions';
import ListCheckboxMoveUpDown from '../List/ListCheckboxMoveUpDown';

const UUid = NewPageId("Urgency");

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    //minHeight: '100%',
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));



export default function SimpleCard(props) {
  const classes = useStyles();
  const [DeawerOpen, SetDeawerOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const ContractorData = useSelector(state => state.ContractorData);
  const UserData = useSelector(state => state.UserData);
  const cid = useSelector(state => state.cid);
  const [loading, SetLoading] = useState(false);
  const initialState = {
    ProgressList: ProgressQuestions,
    UseCustomProgress: true,
  }
  const [state, setState] = useState(initialState);


  //useEffect(() => {

  //  let SelectedDataReturn = {
  //    UseCustomProgress: state.UseCustomProgress,
  //    ProgressList: state.ProgressList,
  //  }

  //  if (props.SelectedDataReturn) {
  //    props.SelectedDataReturn(SelectedDataReturn)
  //  }

  //}, [state.ProgressList, state.UseCustomProgress])

  useEffect(() => {

    //if (props && props.StepValueArray) {
    //  setState(prevState => ({
    //    ...prevState,
    //    ...props.StepValueArray[props.id],
    //  }));
    //}


    let StepValueArray = {};
    if (props.KeyId && props.StepValueArray) {
      StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
    }
    console.log("Priority.StepValueArray", StepValueArray)
    if (props && props.StepValueArray) {
      setState(prevState => ({
        ...prevState,
        ...StepValueArray,
      }));
    }

  }, [])

  //const NewValues = (UseCustomProgress, ProgressList) => {

  //  let SelectedDataReturn = {
  //    UseCustomProgress: UseCustomProgress,
  //    ProgressList: ProgressList,
  //    id: props.id
  //  }
  //  console.log("SMSListQuestions.key", SelectedDataReturn)
  //  if (props.SelectedDataReturn) {
  //    props.SelectedDataReturn(SelectedDataReturn)
  //  }

  //}

  const handleNext = () => {

    let SelectedDataReturn = {
      //UseCustomSMS: UseCustomSMS,
      //SMSQuestions: SMSQuestions,
      ...state,
      id: props.id,
      KeyId: props.KeyId
    }
    console.log("SMSListQuestions.key", SelectedDataReturn)
    if (props.SelectedDataReturn) {
      props.SelectedDataReturn(SelectedDataReturn)
    }

    if (props.handleNext) {
      props.handleNext();
    }

  }

  //const handleExpandClick = () => {
  //  setExpanded(!expanded);
  //};


  const CloseDrawer = () => {

    let SelectedDataReturn = {
      UseCustomProgress: state.UseCustomProgress,
      ProgressList: state.ProgressList,
      id: props.id
    }

    if (props.SelectedDataReturn) {
      props.SelectedDataReturn(SelectedDataReturn)
    }

    SetDeawerOpen(false)
  }



  const AddItem = () => {

    let newitem = {
      key: NewPageId("Prgs"),
      label: "",
      ModuleTitle: "",
      message: "",
      LoadComponenet: "Autocomplete",
      LoadComponenet2: "",
      LoadComponenet3: "",
      Trigger1: false,
      Trigger2: false,
      Trigger3: false,
      required: false,
      DataReceivedArrayName: "SelectedLocation",
      DataSendArrayName: "",
      disabled: false,
      Category: "Custom",
      VariableInput: "",
    }

    //let SMSQuestionList = [...state.SMSQuestionList, newitem];

    setState(prevState => ({
      ...prevState,
      // SMSQuestionList: SMSQuestionList,
      DetailsProps: newitem
    }));

    SetDeawerOpen(true)

  }

  const AdditemDone = () => {
    setState(prevState => ({
      ...prevState,
      TriggerAdd: false,
    }))
  }


  const KeyArray = (data, cat) => {
    //console.log("ProgressList.KeyArray", data, cat)
    setState(prevState => ({
      ...prevState,
      [cat]: data
    }));

   // NewValues(state.UseCustomProgress, data)

  }

  const handleChange = (event, name) => {

    let UseCustomProgress = event.target.checked;
    setState({
      ...state,
      [name]: UseCustomProgress,
    });

  //  NewValues(UseCustomProgress, state.ProgressList)

  };

  const AddBox = (add) => {

    setState({
      ...state,
      DetailsProps: add,
    });

    SetDeawerOpen(true)

  }

  const handleInclude = (data) => {
    

    let ProgressList = state.ProgressList;
    let findindex = ProgressList.findIndex(x => x.key === data.key);

    if (findindex && findindex > -1) {
      ProgressList[findindex] = data;
    } else {
      // if key does not exist, then add as new
      ProgressList = [...state.ProgressList, data];
    }

    console.log("Progress.handleInclude", ProgressList)

    setState(prevState => ({
      ...prevState,
      ProgressList: ProgressList
    }));
  }



    console.log("ProgressList.state", state)
    console.log("ProgressList.props", props)
 


  return (
    
      <div className={classes.paper} id={props.key} key={"key" + props.key}>
      <Grid container spacing={2}>
{/*..
        <Grid item xs={12}>

          <FormGroup>
            <FormControlLabel
              control={<Switch
                onClick={(event) => handleChange(event, "UseCustomProgress")}
                value={state.UseCustomProgress || ""} />}
              label="Use following Custom Progress" />
          </FormGroup>

        </Grid>
*/}
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => AddItem()}
          >
            Add
                    </Button>
        </Grid>

        <Grid item xs={12}>
          {/*  
          <Card className={classes.root}>

            <CardHeader
              title={props.field1}
              subheader={props.field2}
              action={
                    
              }
            />

            <CardContent>
           
          <ProgressStepper
            Data={ProgressQuestions}
            title="Progress Steps"
            ShowUpDown={false}
            //TriggerAdd={state.TriggerAdd}
            //AdditemDone={() => AdditemDone()}
            ShowEdit={false}
            //AddBox={(e) => props.AddBox(e)}
            //DefaultChecked={props.DefaultChecked || []}
            //NextLoadComponenet={props.NextLoadComponenet}
            Category="Progress"
            SelectedDataReturn={(e) => KeyArray(e, "ProgressList")}
            PerPage={5}
          />
           */}

          <ListCheckboxMoveUpDown
            Data={state.ProgressList}
            title="Progress Steps"
            ShowUpDown={true}
            ShowEdit={true}
            AddBox={(e) => AddBox(e)}
            DefaultChecked={state.DefaultChecked || []}
            NextLoadComponenet="AddSetupSelection"
            Category="Progress"
            FullJSONBack={(e) => KeyArray(e, "ProgressList")}
          />

          {/*  
            </CardContent>

          </Card>

          */}
        </Grid>

        <Grid item xs={12}>
          <div>
            <div>
              <Button
                disabled={props.activeStep === 0}
                onClick={() => props.handleBack()}
              // className={classes.button}
              >
                Back
                              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleNext()}
              //  className={classes.button}
              >
                {'Next'}
              </Button>
            </div>
          </div>
        </Grid>

      </Grid>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DrawerShow
        LoadComponenet="ProgressBox"
        CloseDrawer={() => CloseDrawer()}
        DrawerTitle="Progress Step Add/Edit"
        //LoadComponenet="ItemAddPage"
        propss={{
          //CloseDrawer: (e) => CloseDrawer(e),
          //step: state.DetailsProps,
          //handleInclude: (e, f) => console.log("Progress.List.handleInclude", e, f)

          CloseDrawer: (e) => CloseDrawer(e),
          step: state.DetailsProps,
          handleInclude: (e) => handleInclude(e),
          CompanyId: props.CompanyId,
          CompanyName: props.CompanyName,

        }}
        DrawerStatus={DeawerOpen}
        // CloseDrawer={(e) => SetDeawerOpen(false)}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
      />
    </div>
  );
}
