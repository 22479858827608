import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    Button,
    Typography,
    Grid,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    Stack
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Search as SearchIcon } from 'react-feather';
import AutoComplateBox from '../components/AutoComplete';
import { NewPageId } from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';


//const useStyles = makeStyles((theme) => ({
//  root: {},
//  importButton: {
//    marginRight: theme.spacing(1)
//  },
//  exportButton: {
//    marginRight: theme.spacing(1)
//  }
//}));

const Newpageiddd = NewPageId("toolbar")

const Toolbar = (props) => {
    // const classes = useStyles();
    const [SelectCompany, SetSelectCompany] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const [DrawerStatus, OpenDrawer] = useState(false);

    //const [UserData, SetUserData] = useState(UserData);

    const initialState = {
        CurrentCompany: UserData.CompanyName,
    };

    const [state, setState] = useState(initialState);

    const CloseDrawer = (c) => {
        if (props.CloseDrawerToolBar) {
            props.CloseDrawerToolBar();
        }
        OpenDrawer(false);
    }

    useEffect(() => {

        let CurrentCompany = UserData.CompanyName;
        let CurrentCompanyId = UserData.CompanyId;
        let UserData2 = UserData;

        if (UserData.CustomerType === "Contractor") {
            CurrentCompany = ContractorData.CompanyName;
            CurrentCompanyId = ContractorData.CompanyId;
            UserData2 = ContractorData;
        }

            setState(prevState => ({
                ...prevState,
                CurrentCompany: CurrentCompany,
                CurrentCompanyId: CurrentCompanyId
            }));

        props.CompanyData(UserData2);

    }, [])

    

    const GetCompanyName = (data) => {
        console.log("ESCAddPersonal.CompanyData3.Toolbar", data.CompanyId, data.CompanyName);
       
        setState(prevState => ({
            ...prevState,
            CurrentCompany: data.CompanyName,
            CurrentCompanyId: data.CompanyId
        }));

        SetSelectCompany(false);
        props.CompanyData(data);
    }

    //const AddItem = () => {
    //    if (props.AddItem) {
    //        props.AddItem();
    //    }
    //}

    const AddItem = (data) => {
        OpenDrawer(true);
    }

    //console.log("Toolbar.props", props);
    console.log("ESCAddPersonal.Toolbar.state", state);
    console.log("ESCAddPersonal.Toolbar.props", props);

    return (
        <div
            // className={clsx(classes.root, className)}
            key={"key" + Newpageiddd}
            id={"id" + Newpageiddd}
        >

            <Grid container spacing={1} >

                <Grid item xs={12} >
                            <Typography variant="h3">
                                {state.CurrentCompany}
                            </Typography>
                        </Grid>
                       
                <Grid item xs={12} >
                    <Stack
                        direction={{ xs: 'column', lg: 'row' }}
                        spacing={2}
                    >
                    
                        {UserData.CustomerType === "Contractor" && (
                                <>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => SetSelectCompany(!SelectCompany)}
                                >
                                    Change Company
                                </Button>
                                
                                <Button
                                    color="primary"
                                    variant="contained"
                                onClick={() => GetCompanyName(ContractorData)}
                                >
                                {`Back to ${ContractorData.CompanyName}`}
                                </Button>

                                </>
                                )}
                            
                    <Button
                        color="primary"
                        variant="contained"
                                onClick={() => AddItem()}
                                disabled={props.disabled}
                            >
                                {`Add New Person`}
                            </Button>
                                
                    </Stack>
                </Grid>

                {SelectCompany && (
                    <Grid
                        item
                        xs={12}
                    >
                        <AutoComplateBox
                            //SaveDetails={props.SaveDetails}
                            //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                            Category="Company"
                            FormType="Company"
                            title="Company"
                            id={Newpageiddd}
                            ShowEditButton={false}
                            CompanyId={ContractorData.ContractorId}
                            ReturnBackData={e => console.log(e)}
                            PreviewEdit={false}
                            EditItem={e => console.log("EditItem", e)}
                            //FormData={each.FormData}
                            onSubmit={(model) => GetCompanyName(model)}
                        />
                    </Grid>
                )}

                <Grid item xs={12} >

                    <Box mt={3}>
                        <Card>
                            <CardContent>
                                <Box maxWidth={500}>
                                    <TextField
                                        fullWidth
                                        disabled={props.disabled}
                                        onChange={(e) => props.ProcessSearch(e.target.value)}
                                        startadornment={<InputAdornment position="start">
                                            <SvgIcon
                                                fontSize="small"
                                                color="action"
                                            >
                                                <SearchIcon />
                                            </SvgIcon>
                                        </InputAdornment>}
                                        placeholder={`Search ${props.Headings}`}
                                        variant="outlined"
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    </Grid>
                </Grid>

            <DrawerShow
                LoadComponenet="ItemAddPage"
                CloseDrawer={(e) => CloseDrawer(e)}
                propss={{
                    Category: "Person",
                    CompanyId: state.CurrentCompanyId,
                    CompanyName: state.CurrentCompany,
                    CloseDrawer: (e) => CloseDrawer(e)
                }}
                key={state.key}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string,
    ProcessSearch: PropTypes.func
};

export default Toolbar;
