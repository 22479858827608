import React, { useEffect, useState } from 'react';
import {
    TextField,
    //Collapse,
    Typography,
    //Paper,
    Grid,
    Button,
    //Backdrop,
    //CircularProgress,
    Card,
    //FormGroup,
    CardContent,
    CardActions,
    Snackbar,
    FormControl, InputLabel, Select, MenuItem
    //InputLabel, FormControl, MenuItem, Checkbox, 
    //RadioGroup,
    //FormControlLabel,
    //Radio, IconButton, CardHeader,
    //Switch,
    //Tooltip, Divider
} from '@mui/material';
import { NewPageId } from '../utils/CommonGraphql';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
//import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const WaitOptions = [
 //   { value: 'end', label: "End Notification Steps" },
    { value: '0', label: "No Wait" },
    { value: '10', label: "wait 10 minutes" },
    { value: '30', label: "wait 30 minutes" },
    { value: '45', label: "wait 45 minutes" },
    { value: '60', label: "wait 1 hour" },
    { value: '90', label: "wait 1.5 hour" },
    { value: '120', label: "wait 2 hours" },
    { value: '180', label: "wait 3 hours" },
    { value: '240', label: "wait 4 hours" },
    { value: '480', label: "wait 8 hours" },
    { value: '720', label: "wait 12 hours" },
    { value: '1440', label: "wait 24 hours" },
];

export default function RadioButtonsGroup(props) {
  //  const ContractorData = useSelector(state => state.ContractorData);
    const initialState = {
        delay: '60'
    }
    const [state, setState] = useState(initialState);
  //  const [DrawerStatus, OpenDrawer] = useState(false);

   
    useEffect(() => {

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }

      //  console.log("Priority.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }

    }, [])


    const SetValues = (data, name) => {

        setState(prevState => ({
            ...prevState,
            [name]: data
        }));

    }

    const handleNext = () => {

            let SelectedDataReturn = {
                ...state,
                id: props.id,
                KeyId: props.KeyId
            }
            console.log("SMSListQuestions.key", SelectedDataReturn)
            if (props.SelectedDataReturn) {
                props.SelectedDataReturn(SelectedDataReturn)
            }

            if (props.handleNext) {
                props.handleNext();
            }

    }

   
    console.log("SpecialInstructions.state", state)
    console.log("SpecialInstructions.props", props)

    return (
        <>
            <Grid container spacing={1}>
               
                <Grid item xs={12} >
                    
                    <FormControl variant="outlined" style={{ width: '100%' }} >
                        <InputLabel id="demo-simple-select-outlined-label">Delay Before Next Trigger</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            //id={`select-${Item.key}-${index}`}
                            //defaultValue="end"
                           // error={true}
                            required={true}
                            disabled={state.disabled}
                            autoWidth={true}
                            value={state.delay || ""}
                            onChange={(e) => SetValues(e.target.value, "delay")}
                            label="Delay Before Next Trigger"
                        >
                            {WaitOptions.map((o, indd) => (
                                <MenuItem key={indd} value={o.value}>
                                    {o.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl >

                </Grid>
                
                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>

           

        </>

    );
}
