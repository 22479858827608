import React, { useState, useEffect } from 'react';
import { Box, Container, Backdrop, CircularProgress, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Page from '../components/Page';
import ToolbarHeading from '../components/SRToolbarHeading';
import RenderModule from '../utils/RenderModule';
import DrawerShow from '../utils/DrawerModule';
import { NewPageId, MiddlePostSearch, ContreactorPostSearch } from '../utils/CommonGraphql';
import SRList from '../List/SRColumn';
import { adjustedTime, TimeStampEndofDay } from '../utils/DateTime';
import SRThreeColumns from '../List/SRThreeColumns';
import { API, graphqlOperation } from "aws-amplify";
import { onCreateSvcbotDbgsi1, onCreateSvcbotDbgsi2, onCreateSvcbotDb } from '../graphql/subscriptions';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    productCard: {
        height: '100%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));


let Todaydate = new Date();
let olddate = adjustedTime(-30, "Start");
let Todaydatestring = TimeStampEndofDay(Todaydate, "End"); //Todaydate.toISOString();

const ListItems = (props) => {

    let query = useQuery();
    console.log("query", query);

    
    const params = useParams();

    //get Redux
    const UserData = useSelector(state => state.UserData);
    const dispatch = useDispatch();
    const NowEditing = useSelector(state => state.NowEditing)
    const TriggerUpdate = useSelector(state => state.TriggerUpdate)
    const ContractorData = useSelector(state => state.ContractorData);
    const cid = useSelector(state => state.cid);
    //if window is mobile or regular
    const matches = useMediaQuery('(min-width:600px)');
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        Blocks: [],
        SearchWords: "",
        ShowDetails: false,
        anchor: "left",
        BoxWidth: 12,
        //ListType: "ListModule",
        ListType: "ListModule",
        CompanyId: cid.CompanyId,
        pk1: cid.CompanyId,
        propss: {},
        key: NewPageId("SRList"),
        SMSType: "Any",
        StartDate: adjustedTime(-30),
        EndDate: Todaydatestring,
        Sort: "Progress",
        Data: [],
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log('SRPage.params', params);
        console.log('CompanyPage.TabList.SRPage.props', props);

        let Category = "Company";
        let DetailComponent = "Details"
        let ListType = "ListModule";
        let CompanyId = UserData.CompanyId;  //ContractorData.ContractorId;
        let CompanyName = UserData.CompanyName;
        // let pk1 = UserData.CompanyId;

        if (params.Category) {
            Category = params.Category;
        }

        if (props.Category) {
            Category = props.Category;
        }

        if (params.DetailComponent) {
            DetailComponent = params.DetailComponent
        }

        if (props.DetailComponent) {
            DetailComponent = props.DetailComponent
        }

        if (params.ListType) {
            ListType = params.ListType
        }

        if (props.ListType) {
            ListType = props.ListType
        }

        if (params.CompanyId) {
            CompanyId = params.CompanyId
            //   pk1 = params.CompanyId
        }

        if (props.CompanyId) {
            CompanyId = props.CompanyId;
            //    pk1 = props.CompanyId
        }

        //if (UserData.CustomerType === "Contractor" && Category === "Company") {
        //  pk1 = ContractorData.ContractorId;
        //  setState(prevState => ({
        //    ...prevState,
        //    pk1: ContractorData.ContractorId,
        //  }));
        //}

        const UUid = NewPageId(Category);
        setState(prevState => ({
            ...prevState,
            Category: Category,
            UUid: UUid,
            //DetailComponent: DetailComponent,
            ListType: ListType,
            CompanyId: CompanyId
        }));

    
        //get data
        console.log("SRPage.DBinput1", CompanyId, olddate, Todaydatestring);

        GetDatafromDB(CompanyId, olddate, Todaydatestring);

    }, [])

    useEffect(() => {

        if (TriggerUpdate === true) {
            console.log("SRPage.TriggerUpdate", TriggerUpdate);
            GetDatafromDB(state.CompanyId, state.StartDate, state.EndDate);
            dispatch({ type: 'TRIGGER_UPDATE', payload: false });
        }

    }, [TriggerUpdate]);

    useEffect(() => {

        if (state.CompanyId) { 
            
            let subscriptionPost = `Post::${state.CompanyId}`;
            if (props.NowEditing === "Contractor") {
                console.log('SRPage.subscriptiuon.contractor: ', subscriptionPost)
                API.graphql({
                    query: onCreateSvcbotDbgsi2,
                    variables: {
                        gsi2Pk2: subscriptionPost
                    }
                })
                    .subscribe({
                        next: data => {
                            console.log('SRPage.subscriptiuon.data.Contractor: ', data)
                            UpdateSubsData(data.value.data.onCreateSvcbotDb.dataJSON)
                           // GetDatafromDB(state.CompanyId, state.StartDate, state.EndDate);
                        }
                    })
            } else {
                API.graphql({
                    query: onCreateSvcbotDbgsi1,
                    variables: {
                        gsi1Pk2: subscriptionPost
                    }
                })
                    .subscribe({
                        next: data => {
                            console.log('SRPage.subscriptiuon.data: ', data)
                            UpdateSubsData(data.value.data.onCreateSvcbotDb.dataJSON)
                         //   GetDatafromDB(state.CompanyId, state.StartDate, state.EndDate);
                        }
                    })
            }
        }

    }, [state.CompanyId]);

    const UpdateSubsData = SubsData => {

        let newSubdata = JSON.parse(SubsData);

        if (state.Data || state.Data.length > 0) {

            let NewData = [newSubdata, ...state.Data];
            console.log('SRPage.subscriptiuon.data.NewData: ', NewData)
            setState(prevState => ({
                ...prevState,
                Data: NewData
            }));

        } else {
            GetDatafromDB(state.CompanyId, state.StartDate, state.EndDate);
        }        
    }


    const GetDatafromDB = async (pk1, startDate, endDate) => {
        SetLoading(true);
        let NewData = [];
        let AutoSelectData = [];
        console.log("SRPage.DBinput", NowEditing, pk1, startDate, endDate);

        if (NowEditing === "Contractor") {
            NewData = await ContreactorPostSearch("Post::" + pk1, startDate, endDate);
        } else {
            NewData = await MiddlePostSearch("Post::" + pk1, startDate, endDate);
        }

        console.log("SRPage.NewData1", NewData);
        let ShowString = "";
        if (NewData) {
            NewData.map(d => {
                ShowString = JSON.parse(d.dataJSON);
                AutoSelectData.push(ShowString);
            })
        }

        setState(prevState => ({
            ...prevState,
            Data: AutoSelectData,
            FullData: AutoSelectData
        }))

        SetLoading(false);
        console.log("SRPage.NewData2", AutoSelectData);
    }


    // Additional Information Box
    const AddBox = (add) => {
        console.log("AddBox", add);
        let NewKey = NewPageId("SR");
        if (matches) {
            setState(prevState => ({
                ...prevState,
                DetailComponent: add.DetailComponent,
                Category: add.Category,
                key: add.key || NewKey,
                ShowDetails: true,
                DetailsProps: {
                    ...add
                },
            }))

        } else {
            //open Drawer
            setState(prevState => ({
                ...prevState,
                DetailComponent: add.DetailComponent,
                Category: state.Category,
                ShowDetails: false,
                DetailsProps: {
                    title: add.field1,
                    Category: state.Category,
                    ShowEditButton: false,
                    PreviewEdit: false,
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                    Data: add
                },
                key: add.key || NewKey
            }))

            OpenDrawer(true);
        }
    }

    const CompanyData = (data) => {
        console.log("data", data);
        GetDatafromDB(state.Category, data.pk1);
    }

    const OpenNarrowSearch = () => {
        setState(prevState => ({
            ...prevState,
            DetailComponent: "NarrowSearch",
            ShowDetails: false,
            DetailsProps: {
                //title: add.field1,
                Category: state.Category,
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,

                //ShowEditButton: false,
                //PreviewEdit: false,
                //Data: add
            },
            // key: add.key || NewKey
        }))

        OpenDrawer(true);
    }

    const CloseDrawer = (c) => {
        GetDatafromDB(state.Category, state.pk1);
        OpenDrawer(false);
    }

    const SortPref = sort => {

        let FullData = []; // state.FullData;

        if (sort.StartDate !== state.StartDate || sort.EndDate !== state.EndDate) {

            let NewStartDate = TimeStampEndofDay(sort.StartDate, "Start");
            let NewEndDate = TimeStampEndofDay(sort.EndDate, "End");

            GetDatafromDB(state.CompanyId, NewStartDate, NewEndDate);
            setState(prevState => ({
                ...prevState,
                StartDate: NewStartDate,
                EndDate: NewEndDate,
            }));
        }

        if (sort.SMSType && sort.SMSType !== state.SMSType) {

            if (sort.SMSType !== "Any") {
                FullData = state.FullData.filter(x => x.mobile === sort.SMSType)
            } else {
                FullData = state.FullData;
            }

                setState(prevState => ({
                    ...prevState,
                    SMSType: sort.SMSType,
                    Data: FullData
                }));

        }

        if (sort.Sort !== state.Sort) {
            setState(prevState => ({
                ...prevState,
                Sort: sort.Sort,
            }));
        }

    }

    console.log("SRPage.state", state);

    return (
        <Page
            title={state.Category}
        >
            <Container maxWidth={false}>

                <ToolbarHeading
                    SortPref={sort => SortPref(sort)}
                />

                <Box mt={2}>

                    {state.Data && (
                        <SRThreeColumns
                            Sort={state.Sort}
                            Data={state.Data}
                        />
                    )}

                </Box>
            </Container>
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={(e) => CloseDrawer(e)}
                Category={state.Category}

                propss={state.DetailsProps}
                key={state.key}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />
        </Page>
    );
};

export default ListItems;
