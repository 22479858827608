import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
//import clsx from 'clsx';
import {
    Box,
    Button,
    FormControl, InputLabel, Select, MenuItem,
    Backdrop, CircularProgress,
    TextField,
    Stack,
} from '@mui/material';
//import { useSelector } from 'react-redux';
//import { Search as SearchIcon } from 'react-feather';
//import AutoComplateBox from '../components/AutoComplete';
import { getAutoCompletedata } from '../utils/CommonGraphql';
//import DateRange from '../components/DateRange';
import { adjustedTime, TimeStampEndofDay } from '../utils/DateTime'
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useSelector } from 'react-redux';
import GroupsIcon from '@mui/icons-material/Groups';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
//import { adjustedTime } from

export const DocumentType = [

    {
        label: "Manager Approval",
        key: "Approval"
    },
    {
        label: "In Progress",
        key: "Progress"
    },
    {
        label: "Accepted",
        key: "Completed"
    },

];


let Todaydate = new Date();
let olddate = adjustedTime(-30);

const Toolbar = (props) => {
    const ContractorData = useSelector(state => state.ContractorData);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        StartDate: TimeStampEndofDay(olddate, "Start"),
        EndDate: TimeStampEndofDay(Todaydate, "End"),
        Sort: "Progress",
        SMSType: "Any"
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {

        let SortPref = {
            StartDate: state.StartDate,
            EndDate: state.EndDate,
            Sort: state.Sort,
            SMSType: state.SMSType
        }

        if (props.SortPref) {
            props.SortPref(SortPref);
        }

    }, [state.StartDate, state.EndDate, state.Sort, state.SMSType]);

    useEffect(() => {

        GetContractorSMS("AddSMS", ContractorData.ContractorId, "SMSData");

    }, []);

    const GetContractorSMS = async (Category, CompanyId, ArrayName, Asset = "", SMS = "") => {
        SetLoading(true);

        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: ArrayName,
                    ArraySearchString: ":",
                    extraVar: "status",
                    extraValue: "Active"
                },
            ]
        };

        let NewData = await getAutoCompletedata(DataString, CompanyId, Asset, SMS);
        setState(prevState => ({ ...prevState, ...NewData }))
        SetLoading(false);
        // console.log("UrgencyDetails.NewData", NewData);

        console.log("SMSCompanySelect.NewData.SMSs - NewData", NewData);

    }

    const SelectedValues = (name, item) => {

        setState((prevState) => ({
            ...prevState,
            [name]: item,
        }))

        //if start date > end date = change the end date
        if (name === "StartDate") {
            if (new Date(item) > new Date(state.EndDate)) {
                setState((prevState) => ({
                    ...prevState,
                    EndDate: item,
                }))
            }
        }
    }

    return (

        <Box mt={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack
                    direction={{ xs: 'column', lg: 'row' }}
                    spacing={2}
                >

                    <DatePicker
                        value={state.StartDate}
                        label="Start Date"
                        onChange={item => SelectedValues("StartDate", item)}
                        renderInput={(props) => (
                            <TextField variant="outlined" {...props} />
                        )}
                    />

                    <DatePicker
                        label="End Date"
                        value={state.EndDate}
                        onChange={item => SelectedValues("EndDate", item)}
                        renderInput={(props) => (
                            <TextField variant="outlined" {...props} />
                        )}
                    />

                    <FormControl variant="outlined" style={{ width: '300px' }} >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Data List Type
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            //id={`select-${Item.key}-${index}`}
                            defaultValue="All"
                            error={state.ErrorMessage}
                            required={true}
                            disabled={state.disabled}
                            autoWidth={true}
                            value={state.Sort}
                            onChange={(e) => {
                                SelectedValues("Sort", e.target.value);
                            }}
                            label="Data List Type"
                        >

                            {DocumentType.map((o, indd) => (
                                <MenuItem key={indd + o.key} value={o.key}>
                                    {o.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl >

                    {state.SMSData && state.SMSData.length > 0 && (
                        <FormControl variant="outlined" style={{ width: '300px' }} >
                            <InputLabel id="demo-simple-select-outlined-label">
                                Service Call Type
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                //id={`select-${Item.key}-${index}`}
                                defaultValue="Any"
                                error={state.ErrorMessage}
                                required={true}
                                disabled={state.disabled}
                                autoWidth={true}
                                value={state.SMSType}
                                onChange={(e) => {
                                    SelectedValues("SMSType", e.target.value);
                                }}
                                label="Service Call Type"
                            >
                                <MenuItem key="sakjsjdsdkasdljk" value="Any">
                                    Any
                                </MenuItem>
                                {state.SMSData.map((o, indd) => (
                                    <MenuItem key={indd + o.key} value={o.mobile}>
                                        {o.field1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl >
                    )}

                </Stack>

            </LocalizationProvider>

            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </Box>

    );
};

Toolbar.propTypes = {
    className: PropTypes.string,
    ProcessSearch: PropTypes.func
};

export default Toolbar;
