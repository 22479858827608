import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { useEffect } from 'react';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = 'AIzaSyCZPWSnel6lirA1J92d9yhC2yNNf3TRdK4';

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleMaps(props) {
    const [value, setValue] = React.useState(null);
    const [location, setlocation] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    React.useEffect(() => {

        try {

            

        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }
            console.log("GoogleMaps.inputValue", inputValue)

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                console.log("GoogleMaps.newOptions", newOptions)
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
            };

        } catch (err) {
            console.log("GoogleMaps.err", loaded.current, err)
        }

    }, [value, inputValue, fetch]);

    useEffect(() => {
       
        if (value && value.place_id && value.place_id !== "") {

            const geocoder = new window.google.maps.Geocoder();
            let geoloc = "";

            geocoder
                .geocode({ placeId: value.place_id })
                .then(({ results }) => {
                    geoloc = results[0].geometry.location;
                    let NewLocation = {
                        address: value.description,
                        place_id: value.place_id,
                        lat: geoloc.lat(),
                        lng: geoloc.lng()
                    }

                    setlocation(NewLocation)
                    console.log("GoogleMaps.NewLocation", NewLocation)
                })
        }

    }, [value])

    useEffect(() => {

        if (props.AddressObj && location !== null) {
            props.AddressObj(location);
        }

    }, [location])

    console.log("GoogleMaps.props", props)

    return (
        <Grid container alignItems="center">
            {props.defaultValue && props.defaultValue !== "" && (
                <Grid xs={12} item>
                <Typography variant="body2" color="text.secondary">
                        {`Selected: ${props.defaultValue}`}
                </Typography>
            </Grid>
            )}
            <Grid xs={12} item>
        <Autocomplete
            id="google-map-demo"
            sx={{ width: '100%' }}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label="Add/Change a location" fullWidth />
            )}
            renderOption={(props, option) => {
               // console.log("GoogleMaps.renderOption.option", option)
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Box
                                    component={LocationOnIcon}
                                    sx={{ color: 'text.secondary', mr: 2 }}
                                />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}

                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
                />
            </Grid>
        </Grid>
    );
}
