
export const DocumentType = [
   
    {
        label: "Service Reports",
        key: "Service"
    },
    {
        label: "New Service Request",
        key: "Post"
    },
    {
        label: "Proposal/Quotes",
        key: "Proposal"
    },
    {
        label: "Reports",
        key: "Reports"
    },
    {
        label: "Drawings",
        key: "Drawings"
    },
    {
        label: "Manuals",
        key: "Manuals"
    },
]