
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Typography,
    TextField,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import { FormArray } from '../Forms/FormList';
import ComboForm from '../Forms/ComboForm';
import {
    NewPageId
} from '../utils/CommonGraphql';
import Loadable from 'react-loadable';
//import AutocompleteModule from '../components/AutoComplete';
//import DynamicForms from '../Forms/FormShow';

const AutocompleteModule = Loadable({
    loader: () => import('../components/AutoComplete'),
    loading() {
        return <div>Loading...</div>
    }
});

const DynamicForms = Loadable({
    loader: () => import('../Forms/FormShow'),
    loading() {
        return <div>Loading...</div>
    }
});


const ProfileDetails = ({ className, ...rest }) => {
    const [savedetails, Setsavedetails] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const CurrentEdit = useSelector(state => state.CurrentEdit);

    let initialState = {
        FormJSON: [],
        NewFormList: [],
        FormValueArray: [],
        FormJSONBack: [],
        AutoCompleteData: [],
        AutoCompleteReturn: []
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log("ItemAddForm.props", rest);

        GetFormData();

    }, [rest.id, rest.Category]);


    const GetFormData = () => {

        let NewFormDasta = [];
        let FormJSON2 = [];

        if (rest && rest.UpdateExisting && rest.UpdatedataJSON) {

            if (rest.UpdatedataJSON && rest.UpdatedataJSON.AllItemForms) {
                FormJSON2 = rest.UpdatedataJSON.AllItemForms;
            }

            setState(prevState => ({
                ...prevState,
                ShowFormList: FormJSON2,
                NewFormList: FormJSON2,
                CompanyId: rest.CompanyId,
            }));


        } else {
            if (rest.Category) {
                FormJSON2 = FormArray(rest.Category, NewPageId("ff"));
                
                if (FormJSON2 && FormJSON2.length > 0) {
                    setState(prevState => ({
                        ...prevState,
                        ShowFormList: FormJSON2,
                        NewFormList: FormJSON2,
                        CompanyId: rest.CompanyId,
                    }));
                }
            }
        }
    }


    const SaveDetails = () => {

        let ValueAdded = true;
        let AlarmMessage = "formm ---";
        let AllItemForms = state.NewFormList;
        let NewDDD = [];

        let UpdatePk1 = "";
        let UpdateKey = "";

        if (rest.UpdateExisting) {
            UpdatePk1 = rest.UpdatePk1;
            UpdateKey = rest.UpdateKey;
        } 

        AllItemForms.map(each => {
                if (each.FormData && each.FormData.length > 0) {
                    each.FormData.map((dd, indd) => {
                        if (dd.required && dd.ShowValue === "") {
                            ValueAdded = false;
                            AlarmMessage += `* Enter ${dd.label} `;
                        }
                    })
                }                                
            })

        if (ValueAdded) {

            let SaveData = {
                AllItemForms: AllItemForms,
                UpdateKey: UpdateKey,
                UpdatePk1: UpdatePk1,
                UpdateExisting: rest.UpdateExisting
            }

            console.log("ItemAddForm.SaveData1", SaveData)

            if (rest.SaveData) {
                rest.SaveData(SaveData);
            }


        } else {
            setState(prevState => ({
                ...prevState,
                AlarmMessage: AlarmMessage,
                OpenAlert: true
            }));
        }

    }

    const handleClose = (event, reason) => {
        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    //const ReturnBackData = (e) => {
    //    console.log("ItemAddForm.back.ReturnBackData", e)
    //    setState(prevState => ({
    //        ...prevState,
    //        FormJSONBack: e
    //    }));
    //};

    //const AutoCompleteReturn = (e) => {
    //    console.log("ItemAddForm.back.AutoCompleteData", e)

    //    setState(prevState => ({
    //        ...prevState,
    //        AutoCompleteData: e
    //    }));
    //};

    const OnFormSubmit = (model, key) => {
        try {

            console.log("ComboForm.model", key, model);

            let projects = state.NewFormList;

            let DataExtract = {
                ...projects[key],
                ...model,
                ValueAdded: true
            }

            projects[key] = DataExtract;

            setState(prevState => ({
                ...prevState,
                NewFormList: projects
            }));
            console.log("ComboForm.ReturnFormData", key, projects)
            // props to send the data back
            // props.ReturnBackData(projects);

        } catch (err) { }
    };

    const OnAutoCompleteSubmit = (Data, key) => {
        try {

            let projects = state.NewFormList;

            let DataExtract = {
                ...projects[key],
                SearchWords: Data.SearchWords,
                pk1: Data.pk1,
                sk1: Data.sk1,
            }

            projects[key] = DataExtract;
            projects[key].ValueAdded = true;

            setState(prevState => ({
                ...prevState,
                NewFormList: projects
            }));
            console.log("ComboForm.OnAutoCompleteSubmit", projects)
            

        } catch (err) { }
    };

    console.log("ItemAddForm.state", state);
    console.log("ItemAddForm.rest", rest);
    //console.log("Testing.Form.Edit.ItemAddForm.state", state);
    //console.log("Testing.Form.Edit.1.ItemAddForm.props", rest);

    return (
        <div key={rest.id}>

            <Grid
                container
                spacing={1}
            >
                {state.ShowFormList
                    && state.ShowFormList.length > 0
                    && state.ShowFormList.map((each, index) => (
                        each.FormType === "AutoComplate" ? (

                            <Grid
                                item
                                xs={12}
                                key={index + "forrrm"}
                            >
                                <Card>
                                    <CardHeader
                                        title={each.Category}
                                    />
                                    <Divider />
                                    <CardContent>
                                        <AutocompleteModule
                                            key={index + each.key}
                                            id={each.key}
                                            FormType={each.FormType}
                                            title={each.FormName}
                                            SearchWords={each.SearchWords}
                                            Category={each.Category}
                                            CompanyId={rest.CompanyId}
                                            onSubmit={(model) => OnAutoCompleteSubmit(model, index)}
                                        />
                                    </CardContent>
                                </Card>



                            </Grid>
                        ) : (
                            <Grid
                                item
                                xs={12}
                                key={index + "autooo"}
                            >
                                <DynamicForms
                                    key={index + each.key}
                                    id={each.key}
                                    SaveDetails={rest.SaveDetails}
                                    Category={each.Category}
                                    FormType={each.FormType}
                                    DisableForm={rest.DisableForm}
                                    //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                                    CompanyId={rest.CompanyId}
                                    title={each.FormName}
                                    ShowEditButton={false}
                                    UpdateFormData={e => console.log("UpdateFormData", e)}
                                    PreviewEdit={false}
                                    EditItem={e => console.log("EditItem", e)}
                                    FormData={each.FormData}
                                    onSubmit={(model) => OnFormSubmit(model, index)}
                                />


                            </Grid>
                        )))
                }

            </Grid>

            <Divider />
            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
                <Button
                    color="primary"
                    variant="contained"
                    disabled={rest.DisableForm}
                    onClick={() => SaveDetails()}
                >
                    Save details
                </Button>
            </Box>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

        </div>
    );
};


export default ProfileDetails;
