import React, { useState } from 'react';
//import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AutocompleteModule from '../components/AutoComplete';



const useStyles = makeStyles(() => ({
  root: {}
}));

const AutoCompleteBox = ({ className, ...rest }) => {
  const classes = useStyles();
  console.log("AutoCompleteBox props", rest);
  return (

    <Card>
      <CardHeader
        //subheader="The information can be edited"
        title={rest.title}
      />
      <Divider />
      <CardContent>
        <AutocompleteModule
          key={rest.id}
          id={rest.id}
          FormType={rest.FormType}
          title={rest.title}
          //ReturnBackData={e => rest.ReturnBackData(e)}
          //Selection={e => rest.Selection}
          Category={rest.Category}
          SaveDetails={rest.SaveDetails}
          FormData={rest.FormData}
          CompanyId={rest.CompanyId}
          //"Company::8cad9b70-1c84-11ea-a135-2907f46619f3"
          onSubmit={(model) => rest.onSubmit(model)}
        />
      </CardContent>
      <Divider />
    </Card>

  );
};

AutoCompleteBox.propTypes = {
  className: PropTypes.string
};

export default AutoCompleteBox;


/*
coming from Combobox.js
                    key={each.key + index}
                    id={each.key + index}
                    SaveDetails={props.SaveDetails}
                    //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                    CompanyId={props.CompanyId}
                    Category={each.Category}
                    FormType={each.FormType}
                    title={each.FormName}
                    ShowEditButton={false}
                    //ReturnBackData={e => props.ReturnBackData(e)}
                    //Selection={e => props.Selection}
                    PreviewEdit={false}
                    EditItem={e => console.log("EditItem", e)}
                    FormData={each.FormData}
                    onSubmit={(model) => OnAutoCompleteSubmit(model, index)}


<AutocompleteModule
          key={rest.id}
          id={rest.id}
          FormType={rest.FormType}
          title={rest.title}
          //ReturnBackData={e => rest.ReturnBackData(e)}
          //Selection={e => rest.Selection}
          Category={rest.Category}
          SaveDetails={rest.SaveDetails}
          FormData={rest.FormData}
          CompanyId={rest.CompanyId}
          //"Company::8cad9b70-1c84-11ea-a135-2907f46619f3"
          onSubmit={(model) => rest.onSubmit(model)}
        />


*/
