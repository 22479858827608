import React, { useState, useEffect } from 'react';
import {
    NewPageId,
    Pk1SingelItem,
    getAutoCompletedata,
} from '../utils/CommonGraphql';
import { useSelector } from 'react-redux';
import {
    TextField,
    Collapse,
    Paper, Grid, Button,
    Backdrop,
    CircularProgress,
    Card,
    CardContent,
    CardActions,
    InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
    RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
    Switch, Tooltip, Divider
} from '@mui/material';
import DrawerShow from '../utils/DrawerModule';
import ShowDetails from '../Add/ShowDetails';
import SRColumn from '../List/SRColumn';

//import Page from '../components/Page';

const UUid = NewPageId("Urgency");

export default function SimpleCard(props) {
    // const classes = useStyles();
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const [loading, SetLoading] = useState(false);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);

    const initialState = {
        Data: [],
        History: []
    }
    const [state, setState] = useState(initialState);

    //const handleExpandClick = () => {
    //  setExpanded(!expanded);
    //};

    useEffect(() => {
        if (props.Data) {
            GetDatafromDB(props.Data.CompanyId, props.Data.sk1);
            GetHistory("History", props.Data.sk1, "History")
        }
    }, [props.Data])

    const GetDatafromDB = async (pk, sk) => {
        SetLoading(true);

        let NewData2 = await Pk1SingelItem(pk, sk);
        let NewData = await NewData2;
        if (NewData) {
            let Data = JSON.parse(NewData.dataJSON);
            setState(prevState => ({ ...prevState, Data: Data }))
        }

        SetLoading(false);
    }

    const GetHistory = async (Category, ItemId, ArrayName) => {
        try {

            SetLoading(true);

            const DataString = {
                Category: Category,
                FindArray: [
                    {
                        ArrayName: ArrayName,
                        ArraySearchString: ":",
                        extraVar: "",
                        extraValue: ""
                    },
                ]
            };

            let NewData = await getAutoCompletedata(DataString, ItemId);

            setState(prevState => ({
                ...prevState,
                ...NewData

            }))

            console.log("ShowHistory.NewData", NewData);
            SetLoading(false);

        } catch (err) { }
    }

    

    const CloseDrawer = () => {
        //console.log("CloseDrawer", e)
        SetDeawerOpen(false)
    }

    const handleChange = (event, name) => {
        setState({
            ...state,
            [name]: event.target.checked,
        });
    };


    const handleInclude = (data) => {

        console.log("ContactList.data", data)

        let ContactList = state.ContactList;
        let findindex = ContactList.findIndex(x => x.key === data.key);

        if (findindex && findindex > -1) {
            ContactList[findindex] = data;
        } else {
            // if key does not exist, then add as new
            ContactList = [...state.ContactList, data];
        }

        console.log("ContactList.handleInclude", ContactList)

        setState(prevState => ({
            ...prevState,
            ContactList: ContactList
        }));
    }


    console.log("ShowHistory.state", state)
    console.log("ShowHistory.props", props)

    return (
        <>
            <Grid container spacing={2} >

                <Grid item xs={12}>
                    <ShowDetails
                        title={state.field1}
                        Category={state.Category}
                        ShowEditButton={false}
                        PreviewEdit={false}
                        Data={state.Data}
                    />
                </Grid>

                <Grid item xs={12}>
                    {state.History && (
                        <SRColumn
                            ColumnTitle="History"
                            Data={state.History}
                            key={"each2ssss"}
                        />
                    )}
                </Grid>

            </Grid>
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DrawerShow
                LoadComponenet={state.NewLoadComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle="Personal"
                //LoadComponenet="ItemAddPage"
                propss={{
                    Category: "Person",
                    CloseDrawer: (e) => CloseDrawer(e),
                    //step: state.DetailsProps,
                    handleInclude: (e) => handleInclude(e),
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
        </>
    );
}
