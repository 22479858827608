import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  List,
  ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText,
  Card, CardHeader, IconButton, Checkbox,
  Divider, Box, Tooltip, Radio
} from "@mui/material";
import Pagination from '@mui/material/Pagination';
import Skeleton from '../components/Skeleton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paginator: {
    justifyContent: "center",
    padding: "10px"
  }
}));

export default function CheckboxList(props) {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState();
    const [noOfPages, SetnoOfPages] = useState(0);
    const itemsPerPage = props.PerPage || 10;
    const [page, setPage] = useState(1);

  const initialState = {
    DataBack: [],
    propss: {},
    TotalCount: 0
  }
  const [state, setState] = useState(initialState);
 
    useEffect(() => {
        console.log("ListItems.ListCheckBox..Props", props);

        let DataBack = props.DataBack;
        if (DataBack) {            
            setState(prevState => ({ ...prevState, DataBack: DataBack }));
            SetnoOfPages(Math.ceil(DataBack.length / itemsPerPage))
        }
    }, [props.DataBack])

    const handleClick = (event) => {
        props.AddBox(event);
    };

    const NarrowSearch = (search) => {
        try {
            let Newdata = [];
            let RawData = props.DataBack;
            if (search !== "") {
                RawData.map((str, i) => {
                    if (str.SearchWords) {
                        if (str.SearchWords.toLowerCase().includes(search.toLowerCase())) {
                            Newdata.push(str);
                        }
                    }
                })
                //
              //  console.log("Newdata search", Newdata);
            } else {
                Newdata = RawData;
            }
            SetnoOfPages(Math.ceil(Newdata.length / itemsPerPage))
            setState(prevState => ({ ...prevState, DataBack: Newdata }));
        } catch (err) { console.log("NarrowSearch err", err); }
    }

    useEffect(() => {
        if (props.SearchWords && props.SearchWords !== "") {
            NarrowSearch(props.SearchWords);
        }
    }, [props.SearchWords])

  const handleChange = (event) => {
    let New = [event.target.value];
    setSelectedValue(New);
    if (props.SelectedKeyArray) {
      props.SelectedKeyArray(New)
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  

  const SetValues = (VariableInput, IndexOf) => {
    try {
      console.log("Onchange form", VariableInput, IndexOf)
      let NewFormJSON = state.DataBack;
      let SelectedKeyArray = [];
      NewFormJSON[IndexOf].VariableInput = VariableInput;
      NewFormJSON[IndexOf].defaultValue = VariableInput;
      //console.log("NewFormJSON[IndexOf]", NewFormJSON[IndexOf])
      setState(prevState => ({ ...prevState, DataBack: NewFormJSON }));
      if (props.idArray) {
        props.idArray(NewFormJSON);
      }

      NewFormJSON.map(each => {
        if (each.VariableInput) {
          SelectedKeyArray.push(each.key)
        }
      })
      if (props.SelectedKeyArray) {
        props.SelectedKeyArray(SelectedKeyArray)
      }

      //console.log("NewFormJSON", NewFormJSON);
    } catch (err) { console.log("SetValues", err) }
  }

    console.log("ListItems.ListCheckBox.state", state);
    
 
  return (
    <>
    <List className={classes.root}>
              {state.DataBack && state.DataBack.length > 0 ? (
                  state.DataBack.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                      .map((each, ii) => {
        const labelId = `checkbox-list-label-${ii}`;

        return (
          <ListItem
            divider={ii < state.DataBack.length - 1}
            key={ii + "ss55"}
            dense
          >
            <ListItemIcon>
             
              <Checkbox
                value={each.key}
                //checked={selectedValue == each.key}
                //onChange={handleChange}
                id={`check-${ii}i`}
                //name={product.key}
                name={"radio-button-demo" + each.key}
                disabled={props.disabled}
                onChange={(e) => {
                  SetValues(e.target.checked, ii);
                }}
                edge="start"
                checked={each.VariableInput || false}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-label': each.field1 }}
              />

            </ListItemIcon>
            <ListItemText id={labelId} primary={each.field1} />
            <ListItemSecondaryAction>
              {props.ShowEdit && (
                <Tooltip title="Preview/Edit/Copy" aria-label="Preview/Edit/Copy">
                  <MoreVertIcon fontSize="small" onClick={() => handleClick(each)} />
                </Tooltip>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        )
                      })) : (<Skeleton />)
              }
      </List>
      <Divider />
      {noOfPages > 1 && (
        <>
    
      <Box >
        <Pagination
          count={noOfPages}
          page={page}
          onChange={handlePageChange}
          defaultPage={1}
          color="primary"
          size="small"
          showFirstButton
          showLastButton
          classes={{ ul: classes.paginator }}
        />
          </Box>
          </>
      )}
    </>
  );
}
