import React from 'react';
import {
    Grid, Typography,
    Card, CardContent, Avatar, CardHeader
} from '@mui/material';
import {
    FindCategoryIcon,
} from '../utils/CommonGraphql';
import { red } from '@mui/material/colors';
//import { Link } from 'react-router-dom';
import Skeleton from '../components/Skeleton';


export default function SimpleCard(props) {

    // console.log("SRinformation.props", props)

    const getIcon = (filetypes) => {
        let Icontype = "pdf"
        switch (filetypes) {
            case 1:
                Icontype = "zip";
                break;
            case 2:
                Icontype = "pdf";
                break;
            case 3:
                Icontype = "Image";
                break;
        }

        return FindCategoryIcon(Icontype);

    }


    return (

        <Card key={"crfddsgfdsssssd1"}>
            <CardHeader
                title="Documents"
                //subheader="Information"

                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                        {FindCategoryIcon("Documents")}
                    </Avatar>
                }
            />

            <CardContent>
                <Grid container spacing={2}>

                    {props.DocumentList && props.DocumentList.length > 0 ? (
                        props.DocumentList.map((each, indd) => (
                            <>
                                <Grid key={indd + "imgtype"} item xs={2}>
                                    {getIcon(each.filetypes)}
                                </Grid>
                                <Grid item xs={10} key={indd + "imgnme"} >

                                    <Typography>
                                        <a href={each.ImageFullPath} target="_blank">
                                            {each.name}
                                        </a>
                                    </Typography>

                                </Grid>
                            </>
                        ))
                    ) : (<Skeleton />)}

                </Grid>

            </CardContent>
        </Card>

    );
}
