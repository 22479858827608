export const Hours = [
  {
    "key": 0,
    "label": "00:00 AM",
    "value": "00:00"
  },
  {
    "key": 1,
    "label": "00:30 AM",
    "value": "00:30"
  },
  {
    "key": 2,
    "label": "01:00 AM",
    "value": "01:00"
  },
  {
    "key": 3,
    "label": "01:30 AM",
    "value": "01:30"
  },
  {
    "key": 4,
    "label": "02:00 AM",
    "value": "02:00"
  },
  {
    "key": 5,
    "label": "02:30 AM",
    "value": "02:30"
  },
  {
    "key": 6,
    "label": "03:00 AM",
    "value": "03:00"
  },
  {
    "key": 7,
    "label": "03:30 AM",
    "value": "03:30"
  },
  {
    "key": 8,
    "label": "04:00 AM",
    "value": "04:00"
  },
  {
    "key": 9,
    "label": "04:30 AM",
    "value": "04:30"
  },
  {
    "key": 10,
    "label": "05:00 AM",
    "value": "05:00"
  },
  {
    "key": 11,
    "label": "05:30 AM",
    "value": "05:30"
  },
  {
    "key": 12,
    "label": "06:00 AM",
    "value": "06:00"
  },
  {
    "key": 13,
    "label": "06:30 AM",
    "value": "06:30"
  },
  {
    "key": 14,
    "label": "07:00 AM",
    "value": "07:00"
  },
  {
    "key": 15,
    "label": "07:30 AM",
    "value": "07:30"
  },
  {
    "key": 16,
    "label": "08:00 AM",
    "value": "08:00"
  },
  {
    "key": 17,
    "label": "08:30 AM",
    "value": "08:30"
  },
  {
    "key": 18,
    "label": "09:00 AM",
    "value": "09:00"
  },
  {
    "key": 19,
    "label": "09:30 AM",
    "value": "09:30"
  },
  {
    "key": 20,
    "label": "10:00 AM",
    "value": "10:00"
  },
  {
    "key": 21,
    "label": "10:30 AM",
    "value": "10:30"
  },
  {
    "key": 22,
    "label": "11:00 AM",
    "value": "11:00"
  },
  {
    "key": 23,
    "label": "11:30 AM",
    "value": "11:30"
  },
  {
    "key": 24,
    "label": "12:00 PM",
    "value": "12:00"
  },
  {
    "key": 25,
    "label": "12:30 PM",
    "value": "12:30"
  },
  {
    "key": 26,
    "label": "01:00 PM",
    "value": "13:00"
  },
  {
    "key": 27,
    "label": "01:30 PM",
    "value": "13:30"
  },
  {
    "key": 28,
    "label": "02:00 PM",
    "value": "14:00"
  },
  {
    "key": 29,
    "label": "02:30 PM",
    "value": "14:30"
  },
  {
    "key": 30,
    "label": "03:00 PM",
    "value": "15:00"
  },
  {
    "key": 31,
    "label": "03:30 PM",
    "value": "15:30"
  },
  {
    "key": 32,
    "label": "04:00 PM",
    "value": "16:00"
  },
  {
    "key": 33,
    "label": "04:30 PM",
    "value": "16:30"
  },
  {
    "key": 34,
    "label": "05:00 PM",
    "value": "17:00"
  },
  {
    "key": 35,
    "label": "05:30 PM",
    "value": "17:30"
  },
  {
    "key": 36,
    "label": "06:00 PM",
    "value": "18:00"
  },
  {
    "key": 37,
    "label": "06:30 PM",
    "value": "18:30"
  },
  {
    "key": 38,
    "label": "07:00 PM",
    "value": "19:00"
  },
  {
    "key": 39,
    "label": "07:30 PM",
    "value": "19:30"
  },
  {
    "key": 40,
    "label": "08:00 PM",
    "value": "20:00"
  },
  {
    "key": 41,
    "label": "08:30 PM",
    "value": "20:30"
  },
  {
    "key": 42,
    "label": "09:00 PM",
    "value": "21:00"
  },
  {
    "key": 43,
    "label": "09:30 PM",
    "value": "21:30"
  },
  {
    "key": 44,
    "label": "10:00 PM",
    "value": "22:00"
  },
  {
    "key": 45,
    "label": "10:30 PM",
    "value": "22:30"
  },
  {
    "key": 46,
    "label": "11:00 PM",
    "value": "23:00"
  },
  {
    "key": 47,
    "label": "11:30 PM",
    "value": "23:30"
  },
  {
    "key": 48,
    "label": "12:00 AM (End)",
    "value": "24:00"
  }
];

export const CompareDates = (Start, End) => {
  if (new Date(End) > new Date(Start)) {
    return true;
  } else {
    return false
  }
}

export const Dayss = [
  { keyy: 0, checked: true, field2: "", field1: "Sun" },
  { keyy: 1, checked: true, field2: "", field1: "Mon" },
  { keyy: 2, checked: true, field2: "", field1: "Tue" },
  { keyy: 3, checked: true, field2: "", field1: "Wed" },
  { keyy: 4, checked: true, field2: "", field1: "Thu" },
  { keyy: 5, checked: true, field2: "", field1: "Fri" },
  { keyy: 6, checked: true, field2: "", field1: "Sat" },
];

export const WeekOfMonth = [
  { keyy: 1, checked: true, field2: "", field1: "1st Week" },
  { keyy: 2, checked: true, field2: "", field1: "2nd Week" },
  { keyy: 3, checked: true, field2: "", field1: "3rd Week" },
  { keyy: 4, checked: true, field2: "", field1: "4th Week" },
  { keyy: 5, checked: true, field2: "", field1: "5th Week" },
];

export const OccuranceInMonth = [
  { keyy: 1, checked: true, field2: "", field1: "1st Occurance in Month" },
  { keyy: 2, checked: true, field2: "", field1: "2nd Occurance in Month" },
  { keyy: 3, checked: true, field2: "", field1: "3rd Occurance in Month" },
  { keyy: 4, checked: true, field2: "", field1: "4th Occurance in Month" },
  { keyy: 5, checked: true, field2: "", field1: "5th Occurance in Month" },
];

export const MonthsOfYear = [
  { keyy: 0, checked: true, field2: "", field1: "Jan" },
  { keyy: 1, checked: true, field2: "", field1: "Feb" },
  { keyy: 2, checked: true, field2: "", field1: "Mar" },
  { keyy: 3, checked: true, field2: "", field1: "Apr" },
  { keyy: 4, checked: true, field2: "", field1: "May" },
  { keyy: 5, checked: true, field2: "", field1: "Jun" },
  { keyy: 6, checked: true, field2: "", field1: "Jul" },
  { keyy: 7, checked: true, field2: "", field1: "Aug" },
  { keyy: 8, checked: true, field2: "", field1: "Sep" },
  { keyy: 9, checked: true, field2: "", field1: "Oct" },
  { keyy: 10, checked: true, field2: "", field1: "Nov" },
  { keyy: 11, checked: true, field2: "", field1: "Dec" },
];



export const currentTime = () => {
    var dateToday = new Date();
    let Newdatt = dateToday.toISOString();
    return Newdatt;
}

// add or subtract dates
export const adjustedTime = (adjust, StartEnd = "End") => {
    var dateToday = new Date();
  let AdjustedDate = dateToday.setDate(dateToday.getDate() + adjust);
    let NewDate = new Date(AdjustedDate)
    let AdjustEnd = TimeStampEndofDay(NewDate, StartEnd)
 // console.log('Date.AdjustedDate1', AdjustedDate, NewDate)
  //console.log('Date.AdjustedDate2', NewDate.toISOString())
    return AdjustEnd;
}


//const dateToday = new Date();
var dateToday = new Date();
const [month1, day1, year1] = [dateToday.getMonth(), dateToday.getDate(), dateToday.getFullYear()];
let month2 = month1 + 1;
//var d = new Date();
//var year = currentUnixTime.getFullYear();
//var month = currentUnixTime.getMonth();
//var day = currentUnixTime.getDate();
export const DateToday = `${month2}-${day1}-${year1}`;
export const DateTodaySlash = `${month2}/${day1}/${year1}`;

export const TimeStamp = Math.round((new Date()).getTime() / 1000);

export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [month, day, year].join('/');
}

export const TimeStampEndofDay = (date, StartEnd = "End") => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    let datte = [year, month, day].join('-');
    let Stamp = `${datte}T23:59:00.000`

    if (StartEnd === "Start") {
        Stamp = `${datte}T00:00:00.000`;
    }

    return Stamp;
}

export const CombineDateAndTime = (date, time) => {
  let timeString = time.getHours() + ':' + time.getMinutes() + ':00';

  var year = date.getFullYear();
  var month = date.getMonth() + 1; // Jan is 0, dec is 11
  var day = date.getDate();
  var dateString = '' + year + '-' + month + '-' + day;
  var combined = new Date(dateString + ' ' + timeString);
  console.log('combineDateAndTime', combined)
  return combined;
};


//export const FormatDate2 = date => {
//  let d = new Date(date);
//  let totaldate = new Intl.DateTimeFormat('en-US').format(d);
//  //console.log("DateRange.totaldate", totaldate);
//  return totaldate;
//}


//const MakeTime = () => {
  //  var x = 30; //minutes interval
  //  var times = []; // time array
  //  var timelabel = "";
  //  var timevalue = "";
  //  var twelve = "";
  //  var tt = 0; // start time
  //  var ap = ['AM', 'PM']; // AM-PM

  //  //loop to increment the time and push results in array
  //  for (var i = 0; tt < 24 * 60; i++) {
  //    var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
  //    var mm = (tt % 60); // getting minutes of the hour in 0-55 format
  //    if (hh === 12) {
  //      twelve = "12"
  //    } else {
  //      twelve = ("0" + (hh % 12)).slice(-2);
  //    }

  //    timelabel = twelve + ':' + ("0" + mm).slice(-2) + " " + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
  //    timevalue = ("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2);
  //    times[i] = {
  //      key: i,
  //      label: timelabel,
  //      value: timevalue
  //    }
  //    tt = tt + x;
  //  }

  //  console.log("times",times);
  //}
