/*
 * 
 * Shows both contractor and company ESC options
 * check the boxes for each.  this is what will be used in triggering ESC
 * 
 * Props:
 * 
 * {
      title: "Notification / Escalation Policy",
      message: "this is the message for this module 2",
      LoadComponenet: "ESCSelectContractorAndCompany",
      AddBox: (e) => AddBox(e),
      ShowAdd: false,
      DetailComponent: "ESCSetupPage",
      Additem: (add) => Additem(add),
      propss: {
        Data: "",
        CompanyId: props.CompanyId,
        CompanyName: props.CompanyName,
        SelectedDataReturn: (e) => ESCReturn(e),
        SearchWords: "",
        ShowEdit: true,

      }
    },
 * 
 */


import React, { useState, useEffect } from 'react';
//import { makeStyles } from '@mui/styles';
//import { API, graphqlOperation } from "aws-amplify";
//import { createSvcbotDb } from '../graphql/mutations';
//import { removeEmptyStringElements, NewPageId } from '../utils/CommonGraphql';
//import { currentTime } from '../utils/DateTime';
import { useSelector } from 'react-redux';
//import Accordion from '../components/Accordion';
import {
  TextField,
  Collapse,
  Paper, Grid, Button,
  Backdrop,
  CircularProgress,
  Card, FormGroup,
  CardContent,
  CardActions,
  InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
  RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
  Switch, Tooltip, Divider
} from '@mui/material';
import {
  removeEmptyStringElements,
  NewPageId,
  EleminateDuplicateObjects,
  getAutoCompletedata
} from '../utils/CommonGraphql';
//import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//import ExpandLessIcon from '@mui/icons-material/ExpandLess';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerShow from '../utils/DrawerModule';
//import RenderModule from '../utils/RenderModule';
import ShowCheckBoxGroup from '../components/ShowCheckBoxGroup';
import { ServiceEsc } from '../temp/ESCTeams';
//import Page from '../components/Page';

const UUid = NewPageId("Urgency");

//const useStyles = makeStyles((theme) => ({
//  root: {
//    // backgroundColor: theme.palette.background.dark,
//    //minHeight: '100%',
//    padding: theme.spacing(2),
//  },
//  paper: {
//    padding: theme.spacing(2),
//    color: theme.palette.text.secondary,
//  },
//}));



export default function SimpleCard(props) {
 // const classes = useStyles();
  const [DeawerOpen, SetDeawerOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const ContractorData = useSelector(state => state.ContractorData);
  const UserData = useSelector(state => state.UserData);
  const cid = useSelector(state => state.cid);
  const [loading, SetLoading] = useState(false);
  const initialState = {
    //title: "",
    TotalProgress: [],
    showcustom: true,
    ShowContractor: false,
    UseCustomESC: "Default",
    ContractorESC: [],
    ContractorESCDefault: [],
    DrawerTitle: "On-Call Rotation Shift Setup",
    CompanyESC: [],
    TotalESC: [],
    ESC: [],
  }
  const [state, setState] = useState(initialState);

  console.log("UrgencyDetails.ESC-contractor.state", state)

  useEffect(() => {

    console.log("UrgencyDetails.ESC-contractor.props", props)

    // get contractor ESC
    GetDatafromDB("ESC", ContractorData.ContractorId, "Contractor");

    //determin Contractor?
    let ShowContractor = false;
    let showcustom = true;
    if (UserData.CustomerType === "Contractor") {
      ShowContractor = true;
      showcustom = false;
    }

    setState(prevState => ({
      ...prevState,
      ShowContractor: ShowContractor,
      showcustom: showcustom
    }))

    //if (props && props.StepValueArray) {
    //  setState(prevState => ({
    //    ...prevState,
    //    ...props.StepValueArray[props.id],
    //  }));
    //}

    let StepValueArray = {};
    if (props.KeyId && props.StepValueArray) {
      StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
   /// }
    console.log("Priority.StepValueArray", StepValueArray)
    //if (props && props.StepValueArray) {
      setState(prevState => ({
        ...prevState,
        ...StepValueArray,
      }));
    }

   // NewValues(state.UseCustomESC, state.ContractorESC);

  }, [])

  const handleNext = () => {

    let NewData = [];
    if (state.ContractorESCDefault && state.ContractorESCDefault.length > 0) {
      NewData = MakeDB(state.ContractorESCDefault, ContractorData.ContractorId);
    }

      let SelectedDataReturn = {
        UseCustomESC: state.UseCustomESC,
        ContractorESC: NewData,
        ContractorESCDefault: state.ContractorESCDefault,
        id: props.id,
        KeyId: props.KeyId
      }
      //console.log("SMSListQuestions.key", SelectedDataReturn)
      if (props.SelectedDataReturn) {
        props.SelectedDataReturn(SelectedDataReturn)
      }

      if (props.handleNext) {
        props.handleNext();
      }
    

  }

  //make it DB pk1/sk1
  const MakeDB = (sk1array, pk1) => {
    let CompanyDB = [];
    sk1array.forEach(sk1 => {
      CompanyDB.push({
        sk1: sk1,
        pk1: pk1
      })
    })
    return CompanyDB;
  }

  const GetDatafromDB = async (Category, CompanyId, ArrayName) => {
    SetLoading(true);

    const DataString = {
      Category: Category,
      FindArray: [
        {
          ArrayName: ArrayName,
          ArraySearchString: ":",
        },
      ]
    };

    let NewData = await getAutoCompletedata(DataString, CompanyId);
    setState(prevState => ({ ...prevState, ...NewData }))

    SetLoading(false);
    console.log("UrgencyDetails.NewData", NewData);
  }



  //const handleExpandClick = () => {
  //  setExpanded(!expanded);
  //};


  const CloseDrawer = () => {

    GetDatafromDB("ESC", ContractorData.ContractorId, "Contractor");

    SetDeawerOpen(false)
  }



  const Additem = (DetailComponent, Category, CompanyId, CompanyName) => {
    setState(prevState => ({
      ...prevState,
      DetailComponent: DetailComponent,
      ShowDetails: false,
      DetailsProps: {
        Category: Category,
        CompanyId: CompanyId,
        CompanyName: CompanyName,
        CloseDrawer: () => CloseDrawer()
      }
    }))
    SetDeawerOpen(true);
  }


  //const KeyArray = (data, cat) => {
  //  console.log("KeyArray", data, cat)
  //  setState(prevState => ({
  //    ...prevState,
  //    [cat]: data
  //  }));    

  //  if (cat === "CompanyESC") {
  //    NewValues(state.UseCustomESC, data, state.ContractorESC)
  //  }

  //  if (cat === "ContractorESC") {
  //    NewValues(state.UseCustomESC, state.CompanyESC, data)
  //  }
  //}

  const handleChange = (data, name) => {
    //let UseCustomESC = data.target.value;
    setState({
      ...state,
      [name]: data,
      ContractorESCDefault: data,
    });

   // NewValues(state.UseCustomESC, data)
  };

  const KeyArray = (data, cat) => {

    let UseCustomESC = data.target.value;
    let ShowContractor = false;

    switch (UseCustomESC) {
      case "Default":
        ShowContractor = false;
        break;
      case "Custom":
        ShowContractor = true;
        break;
      case "None":
        ShowContractor = false;
        break;
      default:
        ShowContractor = false;
    }

    setState(prevState => ({
      ...prevState,
      ShowContractor: ShowContractor,
      [cat]: UseCustomESC
    }))

   // NewValues(UseCustomESC, state.ContractorESC)

  }


  return (
      <div
        //  className={classes.paper} id={props.NewId} key={"key" + props.NewId}
      >

      <Grid container spacing={2}>

        {props.showcustom && (
          <Grid item xs={12}>

            <FormControl component="fieldset">

              <RadioGroup
                aria-label="Notification Selection"
                defaultValue="Default"
                name="radio-buttons-group"
                //onChange={(e) => KeyArray(e, "Priority")}
                onChange={(data) => KeyArray(data, "UseCustomESC")}
                value={state.UseCustomESC || ""}
              >

                <FormControlLabel value="Default" control={<Radio />} label={`Use all ${ContractorData.ContractorName} Default Notification Policies`} />

                <FormControlLabel value="Custom" control={<Radio />} label={`Only following selected 
${ContractorData.ContractorName} Notification Policies`} />

                <FormControlLabel value="None" control={<Radio />} label={`No ${ContractorData.ContractorName} Notification Policies`} />

              </RadioGroup>
            </FormControl>


          </Grid>
        )}

        {state.ShowContractor && (
          <Grid item xs={12}>
            <ShowCheckBoxGroup
              field1={ContractorData.ContractorName}
              field2="On-Call Rotation Shift List"
              disabled={false}
              AllowDelete={false}
              DeleteItem={(e) => console.log("DeleteItem", e)}
              AllowEdit={false}
              EditItem={(e) => console.log("EditItem", e)}
              AllowAdd={true}
              AddItem={() => Additem("ESCSetupPage", "ESC", ContractorData.ContractorId, ContractorData.ContractorName)}
              Data={state.Contractor}
              ShowUpDown={false}
              ShowEdit={false}
              AddBox={(e) => console.log("EditItem", e)}
              DefaultChecked={state.ContractorESCDefault || []}
              NextLoadComponenet="ESCSetupPage"
              Category="ESC"
              KeyArray={(e) => handleChange(e, "ContractorESCDefault")}
              PerPage={5}
              id={"contratoocf"}

            />
          </Grid>
        )}

        <Grid item xs={12}>
          <div>
            <div>
              <Button
                disabled={props.activeStep === 0}
                onClick={() => props.handleBack()}
              // className={classes.button}
              >
                Back
                              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleNext()}
              //  className={classes.button}
              >
                {'Next'}
              </Button>
            </div>
          </div>
        </Grid>

      </Grid>

          <Backdrop
              //className={classes.backdrop}
              open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DrawerShow
        LoadComponenet={state.DetailComponent}
        CloseDrawer={() => SetDeawerOpen(false)}
        DrawerTitle={state.DrawerTitle}
        //LoadComponenet="ItemAddPage"
        propss={{
          CloseDrawer: (e) => CloseDrawer(e),
          ...state.DetailsProps
        }}
        DrawerStatus={DeawerOpen}
        // CloseDrawer={(e) => SetDeawerOpen(false)}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
      />
    </div>
  );
}
