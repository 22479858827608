import React, { useState, useEffect } from 'react';
import {
    Drawer,
    Grid,

} from '@mui/material';
import { useSelector } from 'react-redux';
//import DynamicForms from '../Forms/FormShow';
import { NewPageId } from '../utils/CommonGraphql';

//import AutoComplateBox from '../components/AutoComplete'

import Loadable from 'react-loadable';

const AutoComplateBox = Loadable({
    loader: () => import('../components/AutoCompleteBox'),
    loading() {
        return <div>Loading...</div>
    }
});

const DynamicForms = Loadable({
    loader: () => import('../Forms/FormShow'),
    loading() {
        return <div>Loading...</div>
    }
});


const FormId = NewPageId("form");
//const useStyles = makeStyles((theme) => ({
//  root: {
//    width: '100%',
//  },

//}));

const AllForms = (props) => {
    //  const classes = useStyles();

    let initialState = {
        FormJSON: [],
        FormValueArray: [],
        AutoFormValueArray: [],
    };

    const [state, setState] = useState(initialState);

    //useEffect(() => {
    //    console.log("ComboForm.Props1", props)
    //    setState(prevState => ({
    //        ...prevState,
    //        FormValueArray: props.FormJSON,
    //    }));

    //}, [])

    useEffect(() => {

        console.log("ComboForm.Props2", props)

        let AutoComplatearrr = [];
        let formarrr = [];

        if (props.FormJSON && props.FormJSON.length > 0) {
            props.FormJSON.map((each, indd) => {
                if (each && each !== undefined) {
                if (each.FormType && each.FormType === "AutoComplate") {
                    AutoComplatearrr.push(each);
                } else {
                    formarrr.push(each)
                    }
                }
            })
        }

        setState(prevState => ({
            ...prevState,
            FormJSON: props.FormJSON,
            //  FormValueArray: props.FormJSON,
            AutoComplatearrr: AutoComplatearrr,
            AutoFormValueArray: AutoComplatearrr,
            formarrr: formarrr,
            FormValueArray: formarrr,
            CompanyId: props.CompanyId
        }));

    }, [props.FormJSON])

    const OnFormSubmit = (model, key) => {
        try {

            console.log("ComboForm.model", key, model);

            let projects = state.FormValueArray;
            projects[key] = model;
            projects[key].ValueAdded = true;

            setState(prevState => ({
                ...prevState,
                FormValueArray: projects
            }));
            console.log("ComboForm.ReturnFormData", key, projects)
            // props to send the data back
            props.ReturnBackData(projects);

        } catch (err) { }
    };

    const OnAutoCompleteSubmit = (model, key) => {
        try {

            console.log("ComboForm.OnAutoCompleteSubmit", key, model);

            let projects = state.AutoFormValueArray;
            projects[key] = model;
            projects[key].ValueAdded = true;

            setState(prevState => ({
                ...prevState,
                AutoFormValueArray: projects
            }));
            console.log("ComboForm.OnAutoCompleteSubmit", projects)
            // props to send the data back
            props.AutoCompleteReturn(projects);

        } catch (err) { }
    };

    console.log("ComboForm.state", state);
    //todo - where to get cid and put as props
    return (

     
        <div
            //className={classes.root}
          //  key={props.id}
        >
            <Grid
                container
                spacing={1}
            >
                {state.formarrr
                    && state.formarrr.length > 0
                    && state.formarrr.map((each, index) => {
                        console.log("ComboForm.each.formarrr", each);
                        return (

                            <Grid
                                item
                                xs={12}
                                key={index + "forrrm"}
                            >
                                
                                <DynamicForms
                                    key={index + "autteeoi"}
                                    id={index + "autddtoi"}
                                    SaveDetails={props.SaveDetails}
                                    Category={each.Category}
                                    FormType={each.FormType}
                                    DisableForm={props.DisableForm}
                                    //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                                    CompanyId={props.CompanyId}
                                    title={each.FormName}
                                    ShowEditButton={false}
                                    UpdateFormData={e => console.log("UpdateFormData", e)}
                                    PreviewEdit={false}
                                    EditItem={e => console.log("EditItem", e)}
                                    FormData={each.FormData}
                                    onSubmit={(model) => OnFormSubmit(model, index)}
                                />
                               

                            </Grid>
                        )
                    }
                    )}


                {state.AutoComplatearrr
                    && state.AutoComplatearrr.length > 0
                    && state.AutoComplatearrr.map((each, ind) => {
                      //  console.log("ComboForm.each.auto", each);
                        return (
                            <Grid
                                item
                                xs={12}
                                key={ind + "autooo"}
                            >
                                <AutoComplateBox
                                    key={ind + "auttoi"}
                                    id={ind + "auttoi"}
                                    SaveDetails={props.SaveDetails}
                                    //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                                    CompanyId={props.CompanyId}
                                    Category={each.Category}
                                    FormType={each.FormType}
                                    title={each.Category}
                                    ShowEditButton={false}
                                    //ReturnBackData={e => props.ReturnBackData(e)}
                                    //Selection={e => props.Selection}
                                    PreviewEdit={false}
                                    EditItem={e => console.log("EditItem", e)}
                                    FormData={each.FormData}
                                    onSubmit={(model) => OnAutoCompleteSubmit(model, ind)}
                                />
                            </Grid>
                        )
                    }
                    )}
            </Grid>

        </div>
  
    );
};




export default AllForms;

