
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider, Container, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import GlobalStyles from './components/GlobalStyles';
import 'react-perfect-scrollbar/dist/css/styles.css';
//import './mixins/chartjs';
import Amplify, { Auth } from 'aws-amplify';
import theme from './theme';
import routes from './routes';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const App = (props) => {

    const Auth = useSelector(state => state.auth);

    const routing = useRoutes(routes(Auth));

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                
                <Container maxWidth="xl" >
                    <Box mt={2}>
                        {routing}
                        </Box>
                </Container>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};


export default App;
