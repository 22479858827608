import React, { useState, useEffect } from 'react';
import {
    Grid, Typography, Box, Card, CardHeader, CardContent, IconButton, Avatar
} from '@mui/material';
//import makeStyles from '@mui/styles/makeStyles';
//import RenderModule from './RenderModule';
//import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
    FindCategoryIcon
} from '../utils/CommonGraphql';
//import { Calendar } from 'react-feather';
import DrawerShow from '../utils/DrawerModule';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { red } from '@mui/material/colors';



const CalendarBox = (props) => {
    // const classes = useStyles();
    //const UUid = NewPageId("Drwr");
    const [DrawerStatus, OpenDrawer] = useState(false);
    const initialState = {
        DetailComponent: "ShowInfoHistory",
        Data: {}
    };

    const [state, setState] = useState(initialState);

    const onSelectEvent = (CompanyId, sk1, field1) => {

        setState(prevState => ({
            ...prevState,
            Data: {
                CompanyId: CompanyId,
                sk1: sk1
            },
            DetailComponent: "ShowInfoHistory",
            DrawerTitle: field1
        }));

        OpenDrawer(true);
    }

    const CloseDrawer = (c) => {
        //  GetDatafromDB("ESC", props.CompanyId, "Shift");
        OpenDrawer(false);
    }

    console.log("ShowPreSelect.state", state);

    return props.PreSelect && (
        <Box>

            {props.PreSelect.map((eachPreSelect, indd) => (
                <Grid container spacing={2} key={indd + "gr11"} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            {eachPreSelect.field1}
                        </Typography>
                        {eachPreSelect.field2 !== "" && (
                            <Typography variant="h5">
                                {eachPreSelect.field2}
                            </Typography>
                        )}
                    </Grid>


                    {eachPreSelect.ContactList && eachPreSelect.ContactList.length > 0 &&
                        eachPreSelect.ContactList.map((each, inssss) => (
                            <Grid item xs={12}>
                                <Card key={inssss + "crd1"}>
                                    <CardHeader
                                        title={each.field1}
                                        subheader={each.field2}
                                        action={
                                            <IconButton aria-label="settings"
                                                onClick={() => onSelectEvent(eachPreSelect.CompanyId, each.key, each.field1)}
                                                size="large">
                                                <MoreVertIcon />
                                            </IconButton>
                                        }
                                        avatar={
                                            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                                {FindCategoryIcon(each.Category)}
                                            </Avatar>
                                        }
                                    />

                                    <CardContent>
                                        <Grid container spacing={0} >

                                            {each.BaseInfo && each.BaseInfo.length > 0 &&
                                                each.BaseInfo.map((eachInfo, sks) => (
                                                    <Grid key={sks + "bbsse"} item xs={12}>
                                                        <Typography>
                                                            {eachInfo.field1}
                                                        </Typography>
                                                    </Grid>
                                                ))}

                                            {each.ExtraInfo && each.ExtraInfo.length > 0 &&
                                                each.ExtraInfo.map((eachInfo, sks) => (
                                                    <Grid key={sks + "bbs22se"} item xs={12}>
                                                        <Typography>
                                                            {eachInfo.field1}
                                                        </Typography>
                                                    </Grid>
                                                ))}

                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                </Grid>
            ))}


            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={state.DrawerTitle}
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    Data: state.Data,
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                    CustomerType: props.CustomerType,
                }}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />
        </Box>
    );
};

export default CalendarBox;

