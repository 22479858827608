import React, { useState, useEffect } from 'react';
//import { makeStyles } from '@mui/styles';
//import { API, graphqlOperation } from "aws-amplify";
//import { deleteSvcbotDb } from '../graphql/mutations';
//import TreeView from '@mui/lab/TreeView';
//import TreeItem from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
//import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//import ArrowRightIcon from '@mui/icons-material/ArrowRight';
//import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
//import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
//import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import DrawerShow from '../utils/DrawerModule';
import {
    FormControl, InputLabel, Select, MenuItem, Grid, Snackbar
} from '@mui/material';
import ListModule from '../List/ListModule';
import Page from '../components/Page';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    NewPageId
} from '../utils/CommonGraphql';


const WaitOptions = [
    { value: 'end', label: "End Notification Steps" },
    { value: '0', label: "No Wait" },
    { value: '10', label: "wait 10 minutes -> Next step" },
    { value: '30', label: "wait 30 minutes -> Next step" },
    { value: '45', label: "wait 45 minutes -> Next step" },
    { value: '60', label: "wait 1 hour -> Next step" },
    { value: '90', label: "wait 1.5 hour -> Next step" },
    { value: '120', label: "wait 2 hours -> Next step" },
    { value: '180', label: "wait 3 hours -> Next step" },
    { value: '240', label: "wait 4 hours -> Next step" },
    { value: '480', label: "wait 8 hours -> Next step" },
    { value: '720', label: "wait 12 hours -> Next step" },
    { value: '1440', label: "wait 24 hours -> Next step" },
];

let AllowDelete = false;
let AllowEdit = false;

export default function GmailTreeView(props) {
    const UserData = useSelector(state => state.UserData);
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);

    const initialState = {
        NotificationStepsList: [],
        StepTeam: 0,
        EditItem: {},
        AlarmMessage: "",
        OpenAlert: false,
    };

    const [state, setState] = useState(initialState);

    //useEffect(() => {

    //    let delayValue = "";
    //    if (props.delayValue && props.delayValue !== "") {
    //        delayValue = props.delayValue;
    //    }

    //    setState(prevState => ({
    //        ...prevState,
    //        StepNumber: props.StepNumber,
    //        delay: delayValue
    //    }));

    //    if (props.Teams && props.Teams.length > 0) {
    //        setState(prevState => ({
    //            ...prevState,
    //            NotificationStepsList: props.Teams,
    //        }));
    //    }

    //    console.log("ESCPerStep.props", props);
    //}, [props]);

    useEffect(() => {

        console.log("ESCPerStep.props", props);
        let StepValueArray = {};
        if (props.StepNumber && props.StepValueArray) {
            let StepValueArray2 = props.StepValueArray.find(x => x.StepNumber === props.StepNumber);
            if (StepValueArray2) {
                StepValueArray = StepValueArray2;
            }
        }
        console.log("ESCPerStep.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }
       
    }, [])

    useEffect(() => {
        if (props.delay) {
            props.delay(state.delay);
        }
    }, [state.delay])

    const handleNext = (delay) => {

        let SelectedDataReturn = {
            ...state,
            delay: delay,
            StepNumber: props.StepNumber
        }
        console.log("ESCPerStep.return data", SelectedDataReturn)
        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.delay) {
            props.delay(delay);
        }
        
        if (props.CloseEditDrawer) {
            props.CloseEditDrawer();
        }

    }

    //const DelayMessage = (delay) => {
    //    if (delay === "end") {
    //        return "Process Ends"
    //    } else {
    //        return `Time Delay - ${delay} minutes`
    //    }
    //}

    //const AddTeam = () => {
    //  props.AddTeam(props.StepNumber)
    //}

    const ClosingDrawer = () => {
        SetDeawerOpen(false);
    }

    //const AddNextStepOrEnd = (data) => {
    //    try {
    //        console.log("ESCPerStep.AddNextStepOrEnd", state.StepNumber, data)
    //        let NotificationStepsList = state.NotificationStepsList;
    //        let StepIndex = NotificationStepsList.findIndex((post) => post.key === data.key);

    //        if (StepIndex > -1) {

    //            NotificationStepsList[StepIndex] = { StepNumber: state.StepNumber, ...data }
    //        } else {

    //            NotificationStepsList.push({
    //                StepNumber: state.StepNumber,
    //                ...data
    //            })
    //        }
    //        console.log("ESCPerStep.NotificationStepsList", NotificationStepsList);

    //        if (props.AddStepData) {
    //            props.AddStepData(NotificationStepsList);
    //        }

    //        setState(prevState => ({
    //            ...prevState,
    //            NotificationStepsList: NotificationStepsList,
    //        }));

    //    } catch (err) { }
    //};

    const AddNextStepOrEnd = (data) => {
        try {
            console.log("ESCPerStep.AddNextStepOrEnd", data)
            let NotificationStepsList = state.NotificationStepsList;
            let StepIndex = NotificationStepsList.findIndex((post) => post.key === data.key);

            if (StepIndex > -1) {

                NotificationStepsList[StepIndex] = { StepNumber: state.StepNumber, ...data }
            } else {

                NotificationStepsList.push({
                    StepNumber: state.StepNumber,
                    ...data
                })
            }
            console.log("ESCPerStep.NotificationStepsList", NotificationStepsList);

            //if (props.AddStepData) {
            //    props.AddStepData(NotificationStepsList);
            //}

            setState(prevState => ({
                ...prevState,
                NotificationStepsList: NotificationStepsList,
            }));

        } catch (err) { }
    };

    const AddTeam = () => {

        let NewTeamNumber = NewPageId("Team"); //state.StepTeam + 1;
        console.log("NewTeamNumber", state.StepTeam, NewTeamNumber);
        setState(prevState => ({
            ...prevState,
           // NextLoadModule: "ESCAddPersonal",
            StepTeam: NewTeamNumber
        }));

        SetDeawerOpen(true);
    }


    //const AddBox = () => {

    //    setState(prevState => ({
    //        ...prevState,
    //        NextLoadModule: "Details",
    //    }));

    //    SetDeawerOpen(true);
    //}

    const DeleteItem = (index) => {

        let NotificationStepsList = state.NotificationStepsList;
        let DeleteFile = NotificationStepsList[index];
        NotificationStepsList.splice(index, 1);
        setState(prevState => ({
            ...prevState,
            NotificationStepsList: NotificationStepsList,
        }));

        //if (props.AddStepData) {
        //    props.AddStepData(NotificationStepsList);
        //}

    }

    const EditItem = (e) => {
        setState(prevState => ({
            ...prevState,
          //  NextLoadModule: "ESCAddPersonal",
            EditItem: e,
            //StepNumber: e.StepNumber,
            //StepTeam: e.StepTeam,
        }));

        SetDeawerOpen(true);
    }

    const AddNewStep = (delay) => {

        setState(prevState => ({
            ...prevState,
            delay: delay,
        }));

        handleNext(delay)

       // 

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const SaveStep = () => {

        let AlarmMessage = "";
        let OpenAlert = false;
        console.log("NotificationStepsList[step].delay", state.delay)
        switch (state.delay) {
            case "end":
                
                AlarmMessage = "";
                OpenAlert = false;

                setState(prevState => ({
                    ...prevState,
                    AlarmMessage: AlarmMessage,
                    OpenAlert: OpenAlert
                }));

                props.gotoStep(9);

                break;
            case undefined:
            case "":
                AlarmMessage = "Please select Delay before next step"
                OpenAlert = true;

                setState(prevState => ({
                    ...prevState,
                    AlarmMessage: AlarmMessage,
                    OpenAlert: OpenAlert
                }));

                break;
            default:
                
                AlarmMessage = "";
                OpenAlert = false;

                setState(prevState => ({
                    ...prevState,
                    AlarmMessage: AlarmMessage,
                    OpenAlert: OpenAlert
                }));

                props.gotoStep(props.CurrentStep + 1);
               // setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
        }

        

    }

    console.log("ESCPerStep.state", state);

    return (
        <Page
            key={props.ESCId}
        >
            <Grid container spacing={2}>

                <Grid item xs={12} key={props.StepNumber + "NotificationStepsListgrid"}>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => AddTeam()}
                    >
                        {`Add Step ${props.StepNumber} Personal Team`}
                    </Button>

                </Grid>

                {
                    state.NotificationStepsList && state.NotificationStepsList.map((eachStep, indd) => {
                        let StepPersonalList = [];
                        let ShowTeam = true;
                        console.log("ESCPerStep.eachStep", eachStep, indd)
                        if (eachStep.TeamData && eachStep.TeamData.StepPersonalList) {
                            StepPersonalList = eachStep.TeamData.StepPersonalList;
                        }

                        if (UserData.CustomerType === "Company") {
                            if (UserData.CompanyId === eachStep.pk1) {
                                AllowDelete = true;
                                AllowEdit = true;
                                ShowTeam = true;
                            } else {
                                AllowDelete = false;
                                AllowEdit = false;
                                ShowTeam = false;
                            }
                        }

                        if (UserData.CustomerType === "Contractor") {
                            AllowDelete = props.AllowDelete;
                            AllowEdit = props.AllowEdit;
                        }

                        return ShowTeam && (

                            <Grid key={indd + "linsketmodd"} item xs={12} >
                                <Card>
                                    <CardHeader
                                        title={eachStep.field1}
                                        subheader={eachStep.field2}
                                        action={
                                            <>
                                                {AllowDelete && (
                                                    <IconButton aria-label="settings"
                                                        onClick={() => DeleteItem(indd)} size="large">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )}
                                                {AllowEdit && (
                                                    <IconButton aria-label="settings"
                                                        onClick={() => EditItem(eachStep)} size="large">
                                                        <EditIcon />
                                                    </IconButton>
                                                )}
                                               
                                            </>
                                        }
                                    />

                                    <CardContent>
                                <ListModule
                                    DataBack={eachStep.StepPersonalList}
                                    title={eachStep.field1}
                                    field1={eachStep.field1}
                                    field2={eachStep.field2}
                                    key={indd + "linsmodd"}
                                  //  AddBox={e => AddBox(e)}
                                    
                                   // DeleteItem={() => DeleteItem(indd)}
                                   // EditItem={() => EditItem(eachStep)}
                                        />

                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    }
                    )
                }

                {state.NotificationStepsList && state.NotificationStepsList.length > 0 && (
                    <>
                    <Grid item xs={12} >
                            <Typography component="div">Select wait time for the next step or "End Notification Step" option to end the steps</Typography>

                        </Grid>
                    <Grid item xs={12} >

                        <FormControl variant="outlined" style={{ width: '100%' }} >
                            <InputLabel id="demo-simple-select-outlined-label">Delay Before Next Step</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                //id={`select-${Item.key}-${index}`}
                                //defaultValue="end"
                                error={true}
                                required={true}
                                disabled={state.disabled}
                                autoWidth={true}
                                value={state.delay || ""}
                                onChange={(e) => AddNewStep(e.target.value)}
                                label="Delay Before Next Step"
                            >
                                {WaitOptions.map((o, indd) => (
                                    <MenuItem key={indd} value={o.value}>
                                        {o.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl >

                    </Grid>
                        </>
                )}

                <Grid item xs={12}>
                    <div
                       // className={classes.actionsContainer}
                    >
                        <div>
                            <Button
                                //disabled={activeStep === 0}
                                onClick={props.handleBack}
                              //  className={classes.button}

                            >
                                Back
                            </Button>

                            {state.delay !== "" ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => SaveStep()}
                                   // className={classes.button}
                                >
                                    {'Next'}
                                </Button>
                            ) : null}
                        </div>
                    </div>
                </Grid>

            </Grid>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

            <DrawerShow
                LoadComponenet="ESCAddPersonal" //{state.NextLoadModule}
                CloseDrawer={() => ClosingDrawer()}
                DrawerTitle="Personal"
                propss={{
                    CloseDrawer: () => ClosingDrawer(),
                    StepPersonal: (e) => AddNextStepOrEnd(e),
                    StepNumber: props.StepNumber,
                    StepTeam: state.StepTeam,
                    ShiftName: props.ShiftName,
                    ShowEditButton: false,
                    disabled: false,
                    EditItem: state.EditItem,
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                    ESCId: props.ESCId
                }}
                DrawerStatus={DeawerOpen}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
        </Page>
    );
}
