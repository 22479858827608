import React, { useEffect, useState } from 'react';
//import { makeStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  //TextField,
  //Collapse,
  //Typography,
  //Paper,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  //FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';

import {
  removeEmptyStringElements,
  NewPageId,
  EleminateDuplicateObjects,
  getAutoCompletedata
} from '../utils/CommonGraphql';
import ShowCheckBoxGroup from '../components/ShowCheckBoxGroup';
import DrawerShow from '../utils/DrawerModule';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    //minHeight: '100%',
    padding: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function RadioButtonsGroup(props) {
  const classes = useStyles();
  const [DeawerOpen, SetDeawerOpen] = useState(false);
  const [loading, SetLoading] = useState(false);
  const initialState = {
    ShowCompany: false,
    ManagerApprovalNeeded: false,
    ManagerShift: [],
  }
  const [state, setState] = useState(initialState);

 

  
  const handleNext = () => {

    let SelectedDataReturn = {
      ManagerApprovalNeeded: state.ManagerApprovalNeeded,
      ManagerShiftDB: MakeDB(state.ManagerShift, props.CompanyId),
      ManagerShift: state.ManagerShift,
      id: props.id,
      KeyId: props.KeyId
    }

    console.log("UrgencyDetails.SMSListQuestions.databack", SelectedDataReturn)

    if (props.SelectedDataReturn) {
      props.SelectedDataReturn(SelectedDataReturn)
    }

    if (props.handleNext) {
      props.handleNext();
    }

  }

  //make it DB pk1/sk1
  const MakeDB = (sk1array, pk1) => {
    let CompanyDB = [];
    sk1array.forEach(sk1 => {
      CompanyDB.push({
        sk1: sk1,
        pk1: pk1
      })
    })
    return CompanyDB;
  }


  useEffect(() => {

    console.log("UrgencyDetails.ManagerApprovalNeeded.props", props)

    if (props && props.StepValueArray) {

      let StepValueArray = {};
      if (props.KeyId && props.StepValueArray) {
        StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
      }
      console.log("Priority.StepValueArray", StepValueArray)

      setState(prevState => ({
        ...prevState,
        ...StepValueArray,
        ...props.StepValueArray[0]
      }));
    }
    
    if (props.CompanyId) {
      GetDatafromDB("ESC", props.CompanyId, "Company");
    }

  }, [])

  useEffect(() => {

    if (state.Priority === "Emergency") {

      setState(prevState => ({
        ...prevState,
        ManagerApprovalNeeded: false
      }));
    }

  }, [state.Priority])

  const KeyArray = (data, cat) => {
    //let newdata = data.target.value
   // console.log("Priority.KeyArray", newdata, cat)
    setState(prevState => ({
      ...prevState,
      [cat]: data
    }));

   // NewValues(state.ManagerApprovalNeeded, data);
  }

  const Switchvalue = (data, cat) => {
    let newdata = data.target.checked
      console.log("ManagerApproval.switch.data", data)
    setState(prevState => ({
      ...prevState,
      [cat]: newdata
    }));

   // NewValues(newdata, state.ManagerShift);
  }

  const GetDatafromDB = async (Category, CompanyId, ArrayName) => {
    SetLoading(true);

    const DataString = {
      Category: Category,
      FindArray: [
        {
          ArrayName: ArrayName,
          ArraySearchString: ":",
        },
      ]
    };

    let NewData = await getAutoCompletedata(DataString, CompanyId);
    setState(prevState => ({ ...prevState, ...NewData }))

    SetLoading(false);
    console.log("UrgencyDetails.NewData", NewData);
  }



  //const handleExpandClick = () => {
  //  setExpanded(!expanded);
  //};

  const Additem = (DetailComponent, Category, CompanyId, CompanyName) => {
    setState(prevState => ({
      ...prevState,
      DetailComponent: DetailComponent,
      ShowDetails: false,
      DetailsProps: {
        Category: Category,
        CompanyId: CompanyId,
        CompanyName: CompanyName,
        CloseDrawer: () => CloseDrawer()
      }
    }))
    SetDeawerOpen(true);
  }


  const CloseDrawer = () => {

    //GetDatafromDB("ESC", ContractorData.ContractorId, "Contractor");

    if (props.CompanyId) {
      GetDatafromDB("ESC", props.CompanyId, "Company");
    }

    SetDeawerOpen(false)
  }

  console.log("ManagerApprovalNeeded.state", state)
  console.log("ManagerApprovalNeeded.props", props)

  return (
    <>
        <Grid container spacing={1}>

          <Grid item xs={12}>
            <FormGroup>
            <FormControlLabel control={<Switch
              disabled={state.Priority === "Emergency"}
                checked={state.ManagerApprovalNeeded || ""}
                onChange={(e) => Switchvalue(e, "ManagerApprovalNeeded")}
              />} label="Manager Approval" />
            </FormGroup>
          </Grid>

        {state.ManagerApprovalNeeded && state.Priority !== "Emergency" ? (
          <Grid item xs={12}>
            <ShowCheckBoxGroup
              field1={props.CompanyName}
              field2="Shift Managers"
              disabled={false}
              AllowDelete={false}
              DeleteItem={(e) => console.log("DeleteItem", e)}
              AllowEdit={false}
              EditItem={(e) => console.log("EditItem", e)}
              AllowAdd={true}
              AddItem={() => Additem("ESCSetupPage", "ESC", props.CompanyId, props.CompanyName)}
              Data={state.Company}
              ShowUpDown={false}
              ShowEdit={false}
              AddBox={(e) => console.log("EditItem", e)}
              DefaultChecked={state.ManagerShift || []}
              NextLoadComponenet="ESCSetupPage"
              Category="ESC"
              KeyArray={(e) => KeyArray(e, "ManagerShift")}
              PerPage={5}
              id={"CompanyESC2"}

            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <div>
            <div>
              <Button
                disabled={props.activeStep === 0}
                onClick={() => props.handleBack()}
              //  className={classes.button}
              >
                Back
                              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleNext()}
              //  className={classes.button}
              >
                {'Next'}
              </Button>
            </div>
          </div>
        </Grid>

    </Grid>

    <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DrawerShow
        LoadComponenet={state.DetailComponent}
        CloseDrawer={() => SetDeawerOpen(false)}
        DrawerTitle={state.DrawerTitle}
        //LoadComponenet="ItemAddPage"
        propss={{
         // CloseDrawer: (e) => ClosingDrawer(e),
          ...state.DetailsProps
        }}
        DrawerStatus={DeawerOpen}
        // CloseDrawer={(e) => SetDeawerOpen(false)}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
      />

    </>
      
  );
}
