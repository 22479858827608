/*
 * 
 * 
 * show list with up and down and check box
 * Addbox will open the details in side window or drawer if mobile 
 * 
 * <ListCheckboxMoveUpDown
              Data={state.Urgency}
              title="Service Actions"
              ShowUpDown={true}
              ShowEdit={true}
              AddBox={(e) => AddBox(e)}
              DefaultChecked={state.DefaultChecked || []}
              NextLoadComponenet="AddSetupSelection"
              Category="Urgency"
              KeyArray={(e) => KeyArray(e, "UrgencySelected")}
              PerPage=
              disabled=
            />
 * 
 * 
 */



import React, { useState, useEffect } from 'react';
import {
    List,
    ListItem,
    Card, CardHeader, IconButton,
    Divider, Box, Tooltip, Checkbox, Backdrop, CircularProgress
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Pagination from '@mui/material/Pagination';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NewPageId } from '../utils/CommonGraphql';
import Skeleton from '../components/Skeleton';

const FormId = NewPageId("List");

//const useStyles = makeStyles(theme => ({
//  root: {
//    width: "100%",
//    backgroundColor: theme.palette.background.paper
//  },
//  item: {
//    padding: theme.spacing(1.2)
//  },
//  avatar: { marginRight: theme.spacing(5) },
//  paginator: {
//    justifyContent: "center",
//    padding: "10px"
//  }
//}));

//propss = {
//  DataBack, RawData, 
//}
//title, SearchWords, AddBox, disabled, ShowUpDown, PerPage

const AllProjects = props => {
    //  const classes = useStyles();
    const initialState = {
        DataBack: [],
        RawData: [],
        PerPageData: [],
        propss: {},
        TotalCount: 0
    }
    const [state, setState] = useState(initialState);
    const itemsPerPage = props.PerPage || 10;
    const [page, setPage] = useState(1);
    const [NewData, SetNewData] = useState([]);
    const [noOfPages, SetnoOfPages] = useState(1);
    const [loading, SetLoading] = useState(false);

    const handleChange = (event, value) => {
        setPage(value);
    };


    // Get data from props
    //useEffect(() => {
    //  console.log("ListCheckBoxupdown.props1", props);
    //  let DataBack = props.Data;
    //  if (DataBack && DataBack.length > 0) {
    //    SetDefaults(DataBack);
    //  }

    //}, [])

    // Get data from props
    useEffect(() => {
        console.log("ListCheckBoxupdown.step1.props", props);
        let DataBack = props.Data;
        if (DataBack && DataBack.length > 0) {
            SetDefaults(DataBack);
        }

    }, [props.Data])

    useEffect(() => {
        
        if (state.RawData && state.RawData.length > 0) {
            SetDefaults(state.RawData);
        }

    }, [props.DefaultChecked])

    // set the default values
    const SetDefaults = DataBack => {

        let SelectedKeyArray = [];

        if (props.DefaultChecked && props.DefaultChecked.length > 0) {
            SelectedKeyArray = props.DefaultChecked;
            DataBack.map((each, index) => {
                if (props.DefaultChecked.indexOf(each.key) > -1) {
                    DataBack[index].VariableInput = true;
                } else {
                    DataBack[index].VariableInput = false;
                }
            })
        }

        console.log("ListCheckBoxupdown.step2.DataBack", DataBack);

        setState(prevState => ({
            ...prevState,
            RawData: DataBack,
            TotalCount: DataBack.length,
            DefaultChecked: SelectedKeyArray,
            //   PageId: FormId,
        }));

        //set per page
        DataPerPage(DataBack, "SetDefaults");

    }

    const DataPerPage = (DataBack, from = "1") => {

        SetnoOfPages(Math.ceil(DataBack.length / itemsPerPage))
        let PerPageData = DataBack.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        console.log("ListCheckBoxupdown.step3.page", page, from);
        console.log("ListCheckBoxupdown.step3.DataBack", DataBack);
        console.log("ListCheckBoxupdown.step3.PerPageData", PerPageData);
        setState(prevState => ({
            ...prevState,
            PerPageData: PerPageData,
        }));
    }

    useEffect(() => {
        NarrowSearch(props.SearchWords);
    }, [props.SearchWords])

    //useEffect(() => {
    //  DataPerPage(state.RawData, "page")
    //}, [page])


    const handleClick = (event) => {
        //console.log("ListCheckBoxupdown.handleClick", event);
        //console.log("ListCheckBoxupdown.step3.handleClick", from, DataBack);
        props.AddBox(event);
    };

    const NarrowSearch = (search) => {

        try {

            if (search && search !== "") {
                let DataBack = [];
                let RawData = props.Data;
                if (search !== "") {
                    RawData.map((str, i) => {
                        if (str.SearchWords) {
                            if (str.SearchWords.toLowerCase().includes(search.toLowerCase())) {
                                DataBack.push(str);
                            }
                        }
                    })
                } else {
                    DataBack = RawData;
                }


                let SelectedKeyArray = [];

                if (state.DefaultChecked && state.DefaultChecked.length > 0) {
                    SelectedKeyArray = state.DefaultChecked;
                    DataBack.map((each, index) => {
                        if (state.DefaultChecked.indexOf(each.key) > -1) {
                            DataBack[index].VariableInput = true;
                        } else {
                            DataBack[index].VariableInput = false;
                        }
                    })
                }

                setState(prevState => ({
                    ...prevState,
                    RawData: DataBack,
                    TotalCount: DataBack.length,
                    DefaultChecked: SelectedKeyArray
                }));

                console.log("ListCheckBoxupdown.step4.PerPageData", state);

                DataPerPage(DataBack, "NarrowSearch")
            }

        } catch (err) {
            console.log("NarrowSearch err", err);
        }
    }

    const SetValues = (VariableInput, keyy) => {
        try {

            let SelectedKeyArr = [];
            let NewFormJSON = state.RawData;
            console.log("ListCheckBoxupdown.NewFormJSON", NewFormJSON);
            let IndexOf = NewFormJSON.findIndex(x => x.key === keyy);
            console.log("ListCheckBoxupdown.selected", VariableInput, keyy, IndexOf);

            NewFormJSON[IndexOf].VariableInput = VariableInput;
            NewFormJSON[IndexOf].defaultValue = VariableInput;

            // console.log("ListCheckbox.totalaray", NewFormJSON);

            if (props.idArray) {
                props.idArray(NewFormJSON);
            }

            NewFormJSON.map(each => {
                if (each.VariableInput) {
                    SelectedKeyArr.push(each.key)
                }
            })

            if (props && props.SelectedDataReturn) {
                props.SelectedDataReturn(SelectedKeyArr)
            }

            if (props && props.FullJSONBack) {
                props.FullJSONBack(NewFormJSON)
            }


            setState(prevState => ({
                ...prevState,
                RawData: NewFormJSON,
                DefaultChecked: SelectedKeyArr
            }));

        } catch (err) {
            console.log("SetValues", err)
        }
    }


    const MoveUp = (index) => {
        let arr = state.RawData;
        let indexB = index - 1;
        let temp = arr[index];
        arr[index] = arr[indexB];
        arr[indexB] = temp;
        console.log("Up arr", index, temp);
        DataPerPage(arr, "MoveUp");
        setState((prevState) => ({ ...prevState, RawData: arr }));
        if (props.idArray) {
            props.idArray(arr);
        }
    };

    const MoveDown = (index) => {
        let arr = state.RawData;
        let indexB = index + 1;
        let temp = arr[index];
        arr[index] = arr[indexB];
        arr[indexB] = temp;
        DataPerPage(arr, "MoveDown");
        console.log("Down arr", index, temp);
        setState((prevState) => ({ ...prevState, RawData: arr }));
        if (props.idArray) {
            props.idArray(arr);
        }
    };


    // VariableInput, field1, field2, key

    console.log("ListCheckBoxupdown.step1.state", state);

    return NewData && (
        <>
            {/*   
      <div
        //  className={classes.root}
          key={FormId}
      >
      <List compoent="span" >
        {state.PerPageData.map((product, ii) => {
            const labelId = `checkbox-list-label-${ii}`;
            console.log("ListCheckbox.eachline", ii, product)
            return (
*/}
            {state.RawData && state.RawData.length > 0 ? (
                <List key={state.uniqueId} sx={{ width: '100%' }}>
                    {state.RawData.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map((product, ii) => (
                            <>

                                <ListItem
                                    divider={ii < state.PerPageData.length - 1}
                                    key={ii + FormId}
                                    dense
                                >
                                    <Checkbox
                                        value={product.VariableInput || false}
                                        id={`check-${ii}i`}
                                        key={ii + FormId + "key"}
                                        name={product.key}
                                        disabled={props.disabled}
                                        onChange={(e) => {
                                            SetValues(e.target.checked, product.key);
                                        }}
                                        edge="start"
                                        checked={product.VariableInput || false}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `checkbox-list-label-${ii}` }}
                                    />

                                    <ListItemText
                                        id={`checkbox-list-label-${ii}`}
                                        primary={product.field1}
                                        secondary={product.field2}
                                    />


                                    {props.ShowUpDown && (
                                        <>
                                            {ii > 0 && (
                                                <Tooltip title="Move Up" aria-label="Move Up">
                                                    <ExpandLessIcon fontSize="small" onClick={() => MoveUp(ii)} />
                                                </Tooltip>
                                            )}
                                            {ii < state.TotalCount - 1 && (
                                                <Tooltip title="Move Down" aria-label="Move Down">
                                                    <ExpandMoreIcon fontSize="small" onClick={() => MoveDown(ii)} />
                                                </Tooltip>
                                            )}
                                        </>
                                    )}
                                    {props.ShowEdit && (
                                        <Tooltip title="Preview/Edit/Copy" aria-label="Edit">
                                            <MoreVertIcon fontSize="small" onClick={() => handleClick(product)} />
                                        </Tooltip>
                                    )}
                                </ListItem>
                            </>
                        )
                        )}
                </List>
            ) : (<Skeleton />)
            }

            {noOfPages > 1 && (
                <>

                    <Box component="span">
                        <Pagination
                            count={noOfPages}
                            page={page}
                            onChange={handleChange}
                            defaultPage={1}
                            color="primary"
                            size="small"
                            showFirstButton
                            showLastButton
                        //  classes={{ ul: classes.paginator }}
                        />
                    </Box>
                </>
            )
            }

            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </>

    );
};

export default AllProjects;
