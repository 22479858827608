import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Grid,
    Hidden,
    List,
    Typography,
    Button,
    ListItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    AlertCircle as AlertCircleIcon,
    Aperture as ApertureIcon,
    BarChart as BarChartIcon,
    Lock as LockIcon,
    Settings as SettingsIcon,
    ShoppingBag as ShoppingBagIcon,
    User as UserIcon,
    UserPlus as UserPlusIcon,
    Users as UsersIcon,
    Home as HomeIcon,
  //  trello as trelloIcon,
    Phone as PhoneCall,
    Calendar as CalendarIcon,
    Map as map,
} from 'react-feather';
import NavItem from './NavItem';
import DrawerShow from '../../../utils/DrawerModule';
import { useSelector, useDispatch } from 'react-redux';
//import AutoComplateBox from '../components/AutoComplete';
import { ContractorTabs, CompanyTabs, HQTabs } from '../../../Company/TabsList';



const items = (props) => [
    {
        href: '/app/dashboard',
        icon: BarChartIcon,
        label: 'Home'
    },
    {
        href: `/app/company`,
        icon: BarChartIcon,
        label: `${props.CompanyName} page`
    },
    {
        href: '/app/customers',
        icon: BarChartIcon,
        label: 'Accounts'
    },
    {
        href: '/app/company',
        icon: PhoneCall,
        label: 'Company'
    },
    {
        href: '/app/servicelist',
        icon: PhoneCall,
        label: 'List Service'
    },
    //{
    //  href: '/app/list/Company',
    //  icon: ShoppingBagIcon,
    //  label: 'List Companies'
    //},
    {
        href: '/app/list/Person',
        icon: UserIcon,
        label: 'List Personal'
    },
    //{
    //  href: '/app/list/Team',
    //  icon: UsersIcon,
    //  label: 'List Teams'
    //},
    {
        href: '/app/list/Calendar',
        icon: CalendarIcon,
        label: 'Calendar'
    },
    {
        href: '/app/setup/pref/Pref',
        icon: SettingsIcon,
        label: 'Setup'
    },

    {
        href: '/app/add/Contractor',
        icon: SettingsIcon,
        label: 'Add Contractor'
    },

    //{
    //  href: '/app/setup/pref/Urgency',
    //  icon: PhoneCall,
    //  title: 'Service Actions'
    //},

    //{
    //  href: '/app/setup/pref/Area',
    //  icon: map,
    //  title: 'Area / Territory'
    //},
    /*
    {
      href: '/app/ListResources',
      icon: UserIcon,
      title: 'List Resources'
    },
    {
      href: '/app/account',
      icon: UserIcon,
      title: 'Account'
    },
    {
      href: '/app/settings',
      icon: SettingsIcon,
      title: 'Settings'
    },
   
    {
      href: '/login',
      icon: LockIcon,
      title: 'Login'
    },
    {
      href: '/register',
      icon: UserPlusIcon,
      title: 'Register'
    },
    {
      href: '/404',
      icon: AlertCircleIcon,
      title: 'Error'
    }
    */
];

const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    },
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: 'flex-start',
        letterSpacing: 0,
        padding: '10px 8px',
        textTransform: 'none',
        width: '100%'
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    title: {
        marginRight: 'auto'
    },
    active: {
        color: theme.palette.primary.main,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium
        },
        '& $icon': {
            color: theme.palette.primary.main
        }
    }
}));

const NavBar = ({ onMobileClose, openMobile, ...rest }) => {
    const classes = useStyles();
    const location = useLocation();
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [OpenContractor, SetOpenContractor] = useState(false);
    const [OpenAccounts, SetOpenAccounts] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const dispatch = useDispatch()
    const cid = useSelector(state => state.cid);
    let navigate = useNavigate();
    

    const initialState = {
        //CurrentCompany: "",
        //OpenAutoComplete: false,
        //OpenAccountMenu: false,
        //OpenContractor: false,
        //DrawerComponenet: "NewServiceSteps",
       // NavList: items({ CompanyName: UserData.CompanyName, CompanyId: UserData.CompanyId }),

        ListMenu: [],
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {

        let Dashboard = {
            href: '/app/dashboard',
            icon: HomeIcon,
            label: 'Home',
            key: 'Home'
        };

        let LogOut = {
            href: '/app/dashboard',
            icon: UserIcon,
            label: 'Log Out',
            key: 'LogOut'
        };

        let TapsProps = {
            CompanyId: UserData.CompanyId,
            CustomerType: UserData.CustomerType,
            CompanyName: UserData.CompanyName,
            NowEditing: "",
            UserEditor: UserData.CustomerType,
            Category: "",
            ContractorId: ContractorData.CompanyId,
            ContractorName: ContractorData.CompanyName,
            ContractorType: "Contractor",
            BranchId: UserData.BranchId,
            HQId: UserData.HQId,

            //CompanyId: CompanyId,
            //CustomerType: CustomerType,
            //CompanyName: CompanyName,
            //NowEditing: NowEditing,
            //UserEditor: UserData.CustomerType,
            //BranchId: UserData.BranchId,
            //HQId: UserData.HQId,
            //Category: Category,
            //ContractorId: ContractorData.CompanyId,
            //ContractorName: ContractorData.CompanyName,
            //ContractorType: "Contractor"
        }

       
        let ListMenu = CompanyTabs(TapsProps);

        switch (UserData.CustomerType) {
            case "Contractor":
                if (UserData.Level > 800) {
                    ListMenu = []; // HQTabs(TapsProps);
                } else {
                    ListMenu = ContractorTabs(TapsProps);
                }
                break;
            case "Company":
                ListMenu = CompanyTabs(TapsProps);
                break;
            default:
                ListMenu = CompanyTabs(TapsProps);
        }

        setState(prevState => ({
            ...prevState,
            ListMenu: [Dashboard, ...ListMenu, LogOut],
        }));

    }, [UserData.CompanyName]);

    //const GetCompanyName = (data) => {
    //  console.log("data", data);

    //  dispatch({ type: 'SET_CID', payload: data });

    //  setState(prevState => ({
    //    ...prevState,
    //    CurrentCompany: data.field1,
    //    OpenAutoComplete: false,
    //    OpenAccountMenu: true
    //  }));

    //  //SetOpenAccounts(false);

    //  //let CurrentCompany = data.field1;

    //  //if (rest.AddCompany) {
    //  //  CurrentCompany = `${state.CurrentCompany} + ${data.field1}`;
    //  //}

    //  //setState(prevState => ({
    //  //  ...prevState,
    //  //  CurrentCompany: CurrentCompany,
    //  //}));

    //  //SetSelectCompany(false);
    //  //rest.AddPersonal(data);

    //}

    const CloseDrawweerr = () => {
        OpenDrawer(false);
    }

    const NewServiceForm = (UrgencyType) => {

        setState(prevState => ({
            ...prevState,
            DrawerComponenet: "NewServiceSteps",
            DetailsProps: {
                UrgencyType: UrgencyType,
                CloseDrawer: () => CloseDrawweerr()
            },
        }));

        OpenDrawer(true);
    }

    const Steptest = (UrgencyType) => {

        setState(prevState => ({
            ...prevState,
            DrawerComponenet: "SelectStepBox",
            DetailsProps: {
                //UrgencyType: UrgencyType,

                CloseDrawer: () => CloseDrawweerr()
            },
        }));

        OpenDrawer(true);
    }

   
    const DetailLink = () => {

        let propss = {
            key: "UpdateMe",

        }

        navigate(`/app/company/${UserData.CompanyId}`, {
            state: {
                ...UserData,
                ActiveCategory: "Person",
                ActiveKey: UserData.key,
                Activefield1: UserData.field1,
                CompanyId: UserData.CompanyId,
                CompanyInfo: {
                    pk1: UserData.CompanyId
                }
            }
        });
    }

    console.log('Navegation.state', state);
    console.log('Navegation.props', rest);

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >

            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                p={2}
            >
                <Avatar
                    className={classes.avatar}
                  //  component={RouterLink}
                    src={UserData.avatar}
                  //  to="/app/account"
                />
                <Typography
                    className={classes.name}
                    color="textPrimary"
                    variant="h5"
                >
                    {UserData.field1}
                </Typography>
                <Typography
                    color="textSecondary"
                    variant="body2"
                >
                    {UserData.field2}
                </Typography>

                <Button
                    color="textSecondary"
                    variant="body2"
                    onClick={() => DetailLink()}
                >
                    Update Me
                </Button>
            </Box>
            <Divider />

            <Box p={2}>
                <List>

                    {state.ListMenu.map((item, indd) => (
                        <NavItem
                            href={item.href}
                            key={item.label}
                            title={item.label}
                            icon={item.icon}
                            item={item}
                        />
                    ))}

                </List>
            </Box>


            <Box flexGrow={1} />
        </Box>
    );





    return <>

        <Hidden lgUp>

            <Drawer
                anchor="left"
                classes={{ paper: classes.mobileDrawer }}
                onClose={onMobileClose}
                open={openMobile}
                variant="temporary"
            >
                {content}
            </Drawer>

        </Hidden>
        <Hidden lgDown>
            <Drawer
                anchor="left"
                classes={{ paper: classes.desktopDrawer }}
                open
                variant="persistent"
            >
                {content}
            </Drawer>
        </Hidden>

        <DrawerShow
            LoadComponenet={state.DrawerComponenet}
            propss={state.DetailsProps}
            key="NewServiceSteps"
            DrawerStatus={DrawerStatus}
            OpenDrawer={(e) => OpenDrawer(e)}
            anchor="right"
        />

    </>;
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

NavBar.defaultProps = {
    onMobileClose: () => { },
    openMobile: false
};

export default NavBar;
