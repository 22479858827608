import React, { useState, useEffect } from 'react';
import {
    Pk1SingelItem,
} from '../utils/CommonGraphql';
import SRDetails from '../List/SRDetails';

export default function SimpleCard(props) {

    const initialState = {
        Data: [],
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log("SRDetailsPkSk.props", props)

        if (props.Data && props.Data.ServiceRequest) {

            if (props.Data.ServiceRequest && props.Data.ServiceRequest.pk1) {
                GetDatafromDB(props.Data.ServiceRequest.pk1, props.Data.ServiceRequest.sk1);
            }
        }

    }, [props.Data])

    const GetDatafromDB = async (pk, sk) => {
        let NewData2 = await Pk1SingelItem(pk, sk);
        let NewData = await NewData2;
        if (NewData) {
            let Data = JSON.parse(NewData.dataJSON);
            setState(prevState => ({
                ...prevState,
                Data: Data
            }))
        }
    }

    console.log("SRDetailsPkSk.state", state)

    return state.Data && (
        <SRDetails
            SREvent={state.Data}
        />
    )
}
