/*
 * Use as box/Card for List with Checkboxs
 * 
 * props List:
 * field1
  field2
  AllowDelete
  DeleteItem
  AllowEdit
  EditItem
  AllowAdd
  AddItem
  Data
  ShowUpDown
  ShowEdit
  AddBox
  DefaultChecked
  NextLoadComponenet
  Category
  KeyArray
 * 
 * 
 * 
 */


import React from 'react';
import { Card, CardHeader, IconButton, Button, CardActions, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ListCheckboxMoveUpDown from '../List/ListCheckboxMoveUpDown'
import DrawerShow from '../utils/DrawerModule';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard(props) {
  const classes = useStyles();
  const [DeawerOpen, SetDeawerOpen] = React.useState(false);
  console.log("ShowCheckboxGroup.props", props)
  const ClosingDrawer = (e) => {
    console.log("ClosingDrawer", e)
    SetDeawerOpen(false)

  }

 

  return <>
    <Card className={classes.root}>

      <CardHeader
        title={props.field1}
        subheader={props.field2}
        action={
          <>
            {props.AllowDelete && (
              <IconButton aria-label="settings" size="large">
                <DeleteIcon onClick={() => props.DeleteItem()} />
              </IconButton>
            )}
            {props.AllowEdit && (
              <IconButton aria-label="settings" size="large">
                <EditIcon onClick={() => props.EditItem()} />
              </IconButton>
            )}

            {props.AllowAdd && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => props.AddItem()}
              >
                Add
              </Button>
            )}

          </>
        }
      />

      <CardContent>

        <ListCheckboxMoveUpDown
          Data={props.Data}
          title={props.field1}
          ShowUpDown={props.ShowUpDown}
          ShowEdit={props.ShowEdit}
          AddBox={(e) => props.AddBox(e)}
          DefaultChecked={props.DefaultChecked || []}
          NextLoadComponenet={props.NextLoadComponenet}
          Category={props.Category}
          SelectedDataReturn={(e) => props.KeyArray(e)}
          PerPage={props.PerPage}
        />


      </CardContent>

  </Card>
  <DrawerShow
      LoadComponenet={props.NextLoadComponenet}
      CloseDrawer={() => ClosingDrawer()}
        //LoadComponenet="ItemAddPage"
        propss={{
          CloseDrawer: (e) => ClosingDrawer(e),
          ...props
        }}
        DrawerStatus={DeawerOpen}
     // CloseDrawer={(e) => SetDeawerOpen(false)}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
      />
  </>;
}

