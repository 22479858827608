import React from 'react';
import { useSelector } from 'react-redux';

const Logo = (props) => {

    const HQData = useSelector(state => state.HQData);

    return HQData && HQData.avatar && (
    <img
      alt="Logo"
          src={HQData.avatar}
          height="50px"
      {...props}
    />
  );
};

export default Logo;
