
import React from "react";

/*

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import '../utils/texteditor.css'

*/

/*
 * Custom "star" icon for the toolbar using an Octicon
 * https://octicons.github.io
 */
//const CustomButton = () => <span className="octicon octicon-star" />;



/*
 * Event handler to be attached using Quill toolbar module (see line 73)
 * https://quilljs.com/docs/modules/toolbar/
 */
function insertStar() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "★");
  this.quill.setSelection(cursorPosition + 1);
}

/*
 * Custom toolbar component including insertStar button and dropdowns
 */
const CustomToolbar = () => (
  <div id="toolbar">
    <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
      <option value="1" />
      <option value="2" />
      <option selected />
    </select>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <select className="ql-color">
      <option value="red" />
      <option value="green" />
      <option value="blue" />
      <option value="orange" />
      <option value="violet" />
      <option value="#d0d1d2" />
      <option selected />
    </select>
  </div>
);

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */

let modules = {
  toolbar: [

    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
};

//let modules = {
//    toolbar: {
//        container: "#toolbar",
//        //handlers: {
//        //    insertStar: insertStar
//        //}
//    },
//    clipboard: {
//        matchVisual: false,
//    }
//};

/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
let formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color"
];

/* 
 * Editor component with custom toolbar and content containers
 
const Quill = (props) => {
  
  return (
    <div className="text-editor">

      <ReactQuill
        onChange={(e) => props.SelectedValues(e)}
        placeholder="Enter Description.."
        modules={modules}
        value={props.VariableInput || ""}
        formats={formats}
        theme={"snow"} // pass false to use minimal theme
      />
    </div>
  )
}

*/

//export default Quill;
