import React, { useState, useEffect } from 'react';
//import { makeStyles } from '@mui/material/styles';
//import Paper from '@mui/material/Paper';
import { TextField, Grid, Typography, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateTodaySlash, CompareDates, Hours } from '../utils/DateTime';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import DateFnsAdapter from '@date-io/date-fns';
//import {
//  DatePicker,
//  //TimePicker,
//  //DateTimePicker,
//  MuiPickersUtilsProvider,
//} from '@material-ui/pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const dateFns = new DateFnsAdapter();

//import Quill from '../components/quill'
//import InputBase from '@mui/material/InputBase';
//import Divider from '@mui/material/Divider';
//import IconButton from '@mui/material/IconButton';
//import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
//import DeleteIcon from '@mui/icons-material/Delete';

//const useStyles = makeStyles((theme) => ({
//  root: {
//    padding: '2px 4px',
//    display: 'flex',
//    alignItems: 'center',
//    // width: 400,
//  },
//  input: {
//    marginLeft: theme.spacing(1),
//    flex: 1,
//  },
//  iconButton: {
//    padding: 10,
//  },
//  divider: {
//    height: 28,
//    margin: 4,
//  },
//}));

//var today = new Date();
//var dd = String(today.getDate()).padStart(2, '0');
//var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
//var yyyy = today.getFullYear();

//let startdate = mm + '/' + dd + '/' + yyyy;

const DateFormat = 'MM/dd/yyyy';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
  formControl: {
    // margin: theme.spacing(1),
  },
}));

export default function CustomizedInputBase(props) {
  const classes = useStyles();
  const initialState = {
    StartTime: "07:00",
    EndTime: "16:00",
    StartDate: "",
    EndDate: "",
  };

  const [state, setState] = useState(initialState);
  //const [selectedDate, handleDateChange] = useState(new Date());

  useEffect(() => {
    let DateRange = [
      {
        label: "Start Date",
        VariableName: "StartDate",
        value: state.StartDate,
        Message: ""
      },
      {
        label: "End Date",
        VariableName: "EndDate",
        value: state.EndDate,
        Message: ""
      },
      {
        label: "Start Time",
        VariableName: "StartTime",
        value: state.StartTime,
        Message: ""
      },
      {
        label: "End Time",
        VariableName: "EndTime",
        value: state.EndTime,
        Message: state.Endtimemessage,
      }
    ];

    props.SelectedValues(DateRange);

  }, [state.StartDate, state.EndDate, state.StartTime, state.EndTime])

  useEffect(() => {
    props.DataSent.map(item => {
      setState((prevState) => ({
        ...prevState,
        [item.VariableName]: item.value,
      })) 
    })
    console.log("DateRange.props.DataSent", props.DataSent)
  }, [])

  const SelectedValues = (name, item) => {

    //let Newdate = dateFns.format(selectedDate, DateFormat)

    //if (name === "StartDate") {
    //  if (!CompareDates(Newdate, state.EndDate)) {
    //    setState((prevState) => ({
    //      ...prevState,
    //      EndDate: Newdate,
    //    })) 
    //  }
    //}

    //if (name === "EndDate") {
    //  if (!CompareDates(state.StartDate, Newdate)) {
    //    //setState((prevState) => ({
    //    //  ...prevState,
    //    //  EndDate: state.StartDate,
    //    //}))

    //    Newdate = state.StartDate

    //  }
    //}
    console.log("DateRange", name, item)
    setState((prevState) => ({
      ...prevState,
      [name]: item,
    }))
  }

  const EndTimeCheck = (e) => {
    let Endtimemessage = ""
    if (e < state.StartTime) {
      Endtimemessage = "Next Day";
    }
    setState(prevState => ({ ...prevState, EndTime: e, EndTimeMessage: Endtimemessage }));
  }

  const StartTimeCheck = (e) => {
    let Endtimemessage = ""
    if (e > state.EndTime) {
      Endtimemessage = "Next Day";
    }
    setState(prevState => ({ ...prevState, StartTime: e, EndTimeMessage: Endtimemessage }));
  }

  console.log("DateRange.selectedDate", state.StartDate, state.EndDate )

  return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={2} >

        {/*   
        <Grid
          key="1rr"
          item
          xs={12}>
          
            <DatePicker
            id="datepickkkeddrr"
            label="Start Date"
            name="StartDate"
            //required={Item.required}
            error={state.DateErr}
            disabled={state.disabled}
            value={state.StartDate || new Date()}
            //format={DateFormat}
            fullWidth
            type="date"
            //defaultValue={startdate}
            inputVariant="outlined"
            className={classes.textField}
            onChange={item => SelectedValues("StartDate", item)}
            InputLabelProps={{
              shrink: true,
            }}
          />
                  
        </Grid>
        */}
        <Grid
          key="1"
          item
          xs={4}>
          <Typography variant="h5" component="h2">
            Start Date:
            </Typography>

        </Grid>
        <Grid
          key="2"
          item
          xs={8}>

          <DatePicker
            value={state.StartDate}
            fullWidth
            onChange={item => SelectedValues("StartDate", item)}
            inputVariant="outlined"
            format={DateFormat}
          />

        </Grid>
        <Grid
          key="3"
          item
          xs={4}>
          <Typography variant="h5" component="h2">
            End Date:
            </Typography>
        </Grid>
        <Grid
          key="4"
          item
          xs={8}>

          <DatePicker
            value={state.EndDate}
            fullWidth
            onChange={item => SelectedValues("EndDate", item)}
            inputVariant="outlined"
            format={DateFormat}
          />

        </Grid>

        <Grid
          key="5"
          item
          xs={4}>
          <Typography variant="h5" component="h2">
            Shift Start Time:
            </Typography>
        </Grid>
        <Grid
          key="6"
          item
          xs={8}>

          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
            <InputLabel id="demo-simple-select-outlined-label">Start Time</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              //id={`select-${Item.key}-${index}`}
              defaultValue={state.StartTime}
              //error={ErrorMessage}
              //required={Item.required}
              disabled={state.disabled}
              autoWidth={true}
              value={state.StartTime || ""}
              onChange={e => StartTimeCheck(e.target.value)}
              label="Start Time"
            >
              {Hours.map((o, indd) => (
                <MenuItem key={indd} value={o.value}>
                  {o.label}
                </MenuItem>
              ))
              }
            </Select>
          </FormControl >


        </Grid>
        <Grid
          key="7"
          item
          xs={4}>

          <Typography variant="h5" component="h2">
            Shift End Time:
              </Typography>
          {state.EndTimeMessage !== "" && (
            <Typography color='error'>
              {state.EndTimeMessage}
            </Typography>
          )}

        </Grid>
        <Grid
          key="8"
          item
          xs={8}
        >


          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
            <InputLabel id="demo-simple-select-outlined-label">End Time</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              //id={`select-${Item.key}-${index}`}
              defaultValue={state.EndTime}
              //error={ErrorMessage}
              //required={Item.required}
              disabled={state.disabled}
              autoWidth={true}
              value={state.EndTime || ""}
              onChange={e => EndTimeCheck(e.target.value)}
              //onChange={e => setState((prevState) => ({ ...prevState, EndTime: e.target.value }))}
              label="End Time"
            >
              {Hours.map((o, indd) => (
                <MenuItem key={indd} value={o.value}>
                  {o.label}
                </MenuItem>
              ))
              }
            </Select>
          </FormControl >



        </Grid>

      </Grid>
      </LocalizationProvider>
  );
}
