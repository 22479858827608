import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from "aws-amplify";
import { useDispatch } from 'react-redux';
//import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const NavItem = ({
  className,
  href,
  icon: Icon,
    title,
  item,
  ...rest
}) => {
    const classes = useStyles();
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const SignOut = () => {
        //setState((prevState) => ({ ...prevState, isAuthenticated: false }));
        Auth.signOut()
            .then(() => {
                console.log("signout success");
                dispatch({ type: 'LOGGED_OUT', payload: false });
                navigate("/login", { state: {} });
            })
            .catch((err) => {
                console.log("signout err", err);
            });
    };


    const DetailLink = item => {
        console.log("NevItem.DetailLink", item)
       // preventDefault();
      //  if (props.DetailLink && props.DetailLink !== "") {
       // let Path = "app/page/Branch"; //item.href;

        if (item.key === "LogOut") {
            SignOut();
        } else {
            navigate(item.href, { state: { item: item.propss } });
        }

        
      //  }
    }

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
          <Button
        //activeClassName={classes.active}
              className={classes.button}
            //   component={RouterLink}
            //  to={href}

             // key={href}
              
             // state={{ myState: item }}
             
              onClick={() => DetailLink(item)}
      >
        {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
        )}
        <span className={classes.title}>
          {title}
        </span>
          </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
