import React, { useEffect, useState } from 'react';
import {
    TextField,
    //Collapse,
    Typography,
    //Paper,
    Grid,
    Button,
    //Backdrop,
    //CircularProgress,
    Card,
    //FormGroup,
    CardContent,
    CardActions,
    Snackbar,
    //InputLabel, FormControl, MenuItem, Checkbox, 
    //RadioGroup,
    //FormControlLabel,
    //Radio, IconButton, CardHeader,
    //Switch,
    //Tooltip, Divider
} from '@mui/material';
import { NewPageId } from '../utils/CommonGraphql';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { red } from '@mui/material/colors';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ClipboardCopy({ copyText }) {
    const [isCopied, setIsCopied] = useState(false);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(copyText)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div>
            <Card>
                <CardContent>
                    
                    <Typography sx={{ color: red[500] }} variant="h4" gutterBottom component="div">
                        {copyText}
                    </Typography>
                        
                </CardContent>
                <CardActions>
                    <Button
                        onClick={handleCopyClick}
                        //{/*variant="contained"*/}
                        //{/*color="primary"*/}
                        startIcon={<ContentCopyIcon />}
                    >
                        {isCopied ? `Copied!` : `Click here to copy email`}
                    </Button>
                </CardActions>
            
                </Card>
        </div>
    );
}

export default function RadioButtonsGroup(props) {
    const ContractorData = useSelector(state => state.ContractorData);
    const initialState = {
        title: "",
        description: "",
        EmailTrigger: "",
    }
    const [state, setState] = useState(initialState);
    const [DrawerStatus, OpenDrawer] = useState(false);

    const EmailText = NewPageId(ContractorData.id);
    const EmailTrigger = `${EmailText}@service-call-cloud.com`

    useEffect(() => {

        let EmailTriggerss = EmailTrigger;
        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }

        if (StepValueArray && StepValueArray.EmailTrigger && StepValueArray.EmailTrigger !== "") {
            EmailTriggerss = StepValueArray.EmailTrigger;
        }

        if (EmailTriggerss) {
            EmailTriggerss = EmailTriggerss.toLowerCase();
        }

            setState(prevState => ({
                ...prevState,
                EmailTrigger: EmailTriggerss,
                ...StepValueArray,
            }));
        

    }, [props.StepValueArray])


    const SetValues = (data, name) => {

        setState(prevState => ({
            ...prevState,
            [name]: data
        }));

    }

    const handleNext = () => {

        if (state.title !== "") {

            let SelectedDataReturn = {
                ...state,
                id: props.id,
                KeyId: props.KeyId
            }
            console.log("SMSListQuestions.key", SelectedDataReturn)
            if (props.SelectedDataReturn) {
                props.SelectedDataReturn(SelectedDataReturn)
            }

            if (props.handleNext) {
                props.handleNext();
            }

        } else {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please enter Title of Email Trigger",
                OpenAlert: true
            }));

        }

    }

    const handleClose = (event, reason) => {

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };


    console.log("SpecialInstructions.state", state)
    console.log("SpecialInstructions.props", props)

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography >Copy following email address and use it for Notification and Escelation sequence.  </Typography>
                </Grid>

                <Grid item xs={12}>
                    <ClipboardCopy copyText={state.EmailTrigger} />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="title"
                        key="title"
                        required
                        disabled={state.disabled}
                        label="Title of Email Trigger"
                        variant="outlined"
                        name="title"
                        value={state.title || ""}
                        fullWidth

                        onChange={(e) => SetValues(e.target.value, "title")}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="SpecialInstructions1"
                        key="SpecialInstructions2"
                        disabled={state.disabled}
                        label="Description (Optional)"
                        variant="outlined"
                        name="SpecialInstructions"
                        value={state.description || ""}
                        fullWidth
                        multiline
                        rows={3}
                        onChange={(e) => SetValues(e.target.value, "description")}
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
            {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}

        </>

    );
}
