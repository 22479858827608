import React, { useEffect, useState } from 'react';
//import Radio from '@mui/material/Radio';
//import RadioGroup from '@mui/material/RadioGroup';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import FormControl from '@mui/material/FormControl';
//import FormLabel from '@mui/material/FormLabel';
//import FormGroup from '@mui/material/FormGroup';
//import Switch from '@mui/material/Switch';
//import Typography from '@mui/material/Typography';
//import Grid from '@mui/material/Grid';
import {
    TextField,
    //Collapse,
    Paper,
    Grid, Button,
    Backdrop,
    CircularProgress,
    Typography,
    Stepper, Step, StepContent, StepLabel,
    Snackbar,
    FormControl, RadioGroup, FormControlLabel, Radio,
    FormGroup, Switch
    //Card,
    //CardContent,
    //CardActions,
    //InputLabel, Select, FormControl, MenuItem, Checkbox, 
    //RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
    //Switch, Tooltip, Divider
} from '@mui/material';
import { useSelector } from 'react-redux';

export default function RadioButtonsGroup(props) {
    const ContractorData = useSelector(state => state.ContractorData);
    const HQData = useSelector(state => state.HQData);

    let workUrl = `https://${HQData.SubDomain}`;

    const initialState = {
        ReplyEmail: "Do-Not-Reply@service-call-cloud.com",
        phone: ContractorData.phone,
        EndOfQuestions: `Notification has been sent to On-Call Personal. Use #new to start new service request. Use #cancel to cancel this service.`,
//You can check the progress by visiting ${workUrl}`,
        AllowServiceMessage: "Service call received, the system is processing the service request",
        DenyServiceMessage: `You are not allowed to Request Service with SMS. Please call main office ${ContractorData.Phone}`,
        NotinSystemMessage: `Sorry, we were not able to find you in our system. 
Please contact the main number ${ContractorData.Phone} to request service`,
        NotificationSent: "Notification has been sent to On-Call team.",
        SMSFeedbackMessages: true,
    }
    const [state, setState] = useState(initialState);


    //useEffect(() => {

    //  let SelectedDataReturn = {
    //    Priority: state.Priority,
    //    id: props.id
    //  }
    // // console.log("UrgencyDetails.Priority.databack", SelectedDataReturn)
    //  if (props.SelectedDataReturn) {
    //    props.SelectedDataReturn(SelectedDataReturn)
    //  }

    //}, [state.Priority])

    useEffect(() => {

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }
        console.log("Priority.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }

    }, [props])

    const handleNext = () => {

        let SelectedDataReturn = {
            ...state,
            id: props.id,
            KeyId: props.KeyId
        }
        console.log("SaveDataBack.Priority", SelectedDataReturn)
        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.handleNext) {
            props.handleNext();
        }

    }

    const SetValues = (data, cat) => {

        // let newdata = data.target.value;

        setState(prevState => ({
            ...prevState,
            [cat]: data
        }));


    }

    console.log("Priority.state", state)
    console.log("Priority.props", props)

    return (
        <Grid container spacing={2}>

            {/*    
      <Grid item xs={12}>
        
        <FormGroup>
          <FormControlLabel
            control={<Switch
              onClick={(event) => SetValues(event.target.checked, "SMSFeedbackMessages")}
              value={state.SMSFeedbackMessages || ""} />}
            label="Use following Custom SMS Feedback Messages" />
        </FormGroup>

      </Grid>
      */}


            <Grid item xs={12}>
                <TextField
                    id="phone"
                    key="phone"
                    disabled={state.disabled}
                    label="Company Service Number"
                    variant="outlined"
                    name="phone"
                    value={state.phone || ""}
                    fullWidth
                    onChange={(e) => SetValues(e.target.value, "phone")}
                />
            </Grid>
            {/* 
            <Grid item xs={12}>
                <TextField
                    id="AllowServiceMessage1"
                    key="AllowServiceMessage2"
                    disabled={state.disabled}
                    label="Allow Service Message"
                    variant="outlined"
                    name="AllowServiceMessage"
                    value={state.AllowServiceMessage || ""}
                    fullWidth
                    multiline
                    rows={2}
                    onChange={(e) => SetValues(e.target.value, "AllowServiceMessage")}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="NotinSystemMessage1"
                    key="NotinSystemMessage2"
                    disabled={state.disabled}
                    label="Not in System Message"
                    variant="outlined"
                    name="NotinSystemMessage"
                    value={state.NotinSystemMessage || ""}
                    fullWidth
                    multiline
                    rows={2}
                    onChange={(e) => SetValues(e.target.value, "NotinSystemMessage")}
                />
            </Grid>
            */}
            <Grid item xs={12}>
                <TextField
                    id="EndOfQuestions1"
                    key="EndOfQuestions2"
                    disabled={state.disabled}
                    label="End Of Questions"
                    variant="outlined"
                    name="EndOfQuestions"
                    value={state.EndOfQuestions || ""}
                    fullWidth
                    multiline
                    rows={4}
                    onChange={(e) => SetValues(e.target.value, "EndOfQuestions")}
                />
            </Grid>
            {/* 
            <Grid item xs={12}>
                <TextField
                    id="NotificationSent1"
                    key="NotificationSent2"
                    disabled={state.disabled}
                    label="Notification Sent Message"
                    variant="outlined"
                    name="NotificationSent"
                    value={state.NotificationSent || ""}
                    fullWidth
                    multiline
                    rows={2}
                    onChange={(e) => SetValues(e.target.value, "NotificationSent")}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="DenyServiceMessage1"
                    key="DenyServiceMessage2"
                    disabled={state.disabled}
                    label="Message to Deny Service"
                    variant="outlined"
                    name="DenyServiceMessage"
                    value={state.DenyServiceMessage || ""}
                    fullWidth
                    multiline
                    rows={2}
                    onChange={(e) => SetValues(e.target.value, "DenyServiceMessage")}
                />
            </Grid>
            */}
            <Grid item xs={12}>
                <TextField
                    id="ReplyEmail1"
                    key="ReplyEmail2"
                    disabled={state.disabled}
                    label="Reply Email Address for email notification"
                    variant="outlined"
                    name="ReplyEmail"
                    value={state.ReplyEmail || ""}
                    fullWidth
                    //multiline
                    //rows={2}
                    onChange={(e) => SetValues(e.target.value, "ReplyEmail")}
                />
            </Grid>

            <Grid item xs={12}>
                <div>
                    <div>
                        <Button
                            disabled={props.activeStep === 0}
                            onClick={() => props.handleBack()}
                        // className={classes.button}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleNext()}
                        //  className={classes.button}
                        >
                            {'Next'}
                        </Button>
                    </div>
                </div>
            </Grid>
        </Grid>

    );
}
