

export const ContractorBlocks = (props) => [
  {
    title: "Default Priority",
    message: "",
    LoadComponenet: "PrioritySelection",
    id: "PrioritySelection",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "SMSAddEdit",
    Additem: (add) => props.Additem(add),
    ReturnArray: "StepReturn",
    propss: {
      Data: "",
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "Priority"),
      SearchWords: "",
      ShowEdit: true,

    }
  },
  {
    title: "SMS Questions",
    message: "",
    LoadComponenet: "SMSListQuestions",
    id: "SMSListQuestions",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "SMSAddEdit",
    Additem: (add) => props.Additem(add),
    ReturnArray: "StepReturn",
    propss: {
   //   Data: props.ContractorData,
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "StepReturn"),
      SearchWords: "",
      ShowEdit: true,

    }
  },
  {
    title: "Show Progress Form",
    message: "",
    LoadComponenet: "ProgressList",
    id: "ProgressList",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "ProgressSelectSteps",
    Additem: (add) => props.Additem(add),
    ReturnArray: "ProgressReturn",
    propss: {
      Data: "",
      CompanyId: props.CompanyId,
      DetailComponent: "ProgressSelectSteps",
      CompanyName: props.CompanyName,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "ProgressReturn"),
      SearchWords: "",
      ShowEdit: true,

    }
  },
  {
    title: `Select ${props.ContractorName} Notification per shift`,
    message: "",
    LoadComponenet: "ESCContractorDefault",
    id: "ESCSelectContractor",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "ESCSetupPage",
    Additem: (add) => props.Additem(add),
    ReturnArray: "ESCReturn",
    propss: {
     // Data: props.ContractorData,
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "ESCContractor"),
      SearchWords: "",
      ShowEdit: true,
      showcustom: true,
    },
  },
  
  {
    title: "Select Box Color",
    message: "",
    LoadComponenet: "ColorSelection",
    id: "ColorSelection",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "",
    Additem: (add) => props.Additem(add),
    ReturnArray: "ColorSelected",
    propss: {
      Data: "",
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "ColorSelected"),
      SearchWords: "",
      ShowEdit: true,
      showcustom: true,
    }
  },


  {
    title: "SMS Feedback Messages",
    message: "SMS Feedback Messages",
    LoadComponenet: "SMSMessagesBack",
    id: "SMSMessagesBack",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "",
    Additem: (add) => props.Additem(add),
    ReturnArray: "SMSMessagesBack",
    propss: {
      Data: "",
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "SMSMessagesBack"),
      SearchWords: "",
      ShowEdit: true,
      showcustom: true,
    }
  },
];

export const CompanyBlocks = (props) => [
  {
    title: "Default Priority",
    message: "this is the message for this module",
    LoadComponenet: "PrioritySelection",
    id: "PrioritySelection",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "SMSAddEdit",
    Additem: (add) => props.Additem(add),
    ReturnArray: "StepReturn",
    propss: {
      Data: "",
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
      Priority: props.Priority,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "Priority"),
      SearchWords: "",
      ShowEdit: true,

    }
  },
  {
    title: "Manager Approval Step",
    message: `Manager approval process will send a message to the manager on the shift selected below.  
      Once approved by manager, the system will trigger Notification 
Sequence. Managerial approval can be setup for any priority exept Emergency. The Emergency priority will 
bypass manager approval step and trigger notificaiton immediately`,
    LoadComponenet: "ManagerApproval",
    id: "ManagerApproval",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "ESCSetupPage",
    Additem: (add) => props.Additem(add),
    ReturnArray: "ESCReturn",
    propss: {
    //  Data: props.CompanyData,
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "ManagerApprovalSelection"),
      Priority: props.Priority,
      SearchWords: "",
      ShowEdit: true,
      showcustom: true,
    },
  },
  {
    title: "SMS Questions",
    message: "this is the message for this module",
    LoadComponenet: "SMSListQuestions",
    id: "SMSListQuestions",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "SMSAddEdit",
    Additem: (add) => props.Additem(add),
    ReturnArray: "StepReturn",
    propss: {
    //  Data: props.ContractorData,
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "StepReturn"),
      SearchWords: "",
      ShowEdit: true,

    }
  },
  {
    title: "Show Progress Form",
    message: "this is the message for this module 2",
    LoadComponenet: "ProgressList",
    id: "ProgressList",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "ProgressSelectSteps",
    Additem: (add) => props.Additem(add),
    ReturnArray: "ProgressReturn",
    propss: {
      Data: "",
      CompanyId: props.CompanyId,
      DetailComponent: "ProgressSelectSteps",
      CompanyName: props.CompanyName,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "ProgressReturn"),
      SearchWords: "",
      ShowEdit: true,

    }
  },
  {
    title: `Select ${props.ContractorName} Notification per shift`,
    message: "this is the message for this module 2",
    LoadComponenet: "ESCSelectContractor",
    id: "ESCSelectContractor",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "ESCSetupPage",
    Additem: (add) => props.Additem(add),
    ReturnArray: "ESCReturn",
    propss: {
     // Data: props.ContractorData,
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "ESCContractor"),
      SearchWords: "",
      ShowEdit: true,
      showcustom: true,
    },
  },
  {
    title: `Select ${props.CompanyName} Notification per shift`,
    message: "this is the message for this module 2",
    LoadComponenet: "ESCSelectCompany",
    id: "ESCSelectCompany",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "ESCSetupPage",
    Additem: (add) => props.Additem(add),
    ReturnArray: "ESCReturn",
    propss: {
      Data: props.CompanyData,
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "ESCCompany"),
      SearchWords: "",
      ShowEdit: true,
      showcustom: true,
    },
    },

  {
    title: "Special Instructions",
    message: "This section is to list any special instructions, policy, or procedures that the service tech needs to know",
    LoadComponenet: "SpecialInstructions",
    id: "SpecialInstructions",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "",
    Additem: (add) => props.Additem(add),
    ReturnArray: "SpecialInstructions",
    propss: {
      Data: "",
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "SpecialInstructions"),
      SearchWords: "",
      ShowEdit: true,
      showcustom: true,
    }
  },
  {
    title: "Select Box Color",
    message: "this is the message for this module 2",
    LoadComponenet: "ColorSelection",
    id: "ColorSelection",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "",
    Additem: (add) => props.Additem(add),
    ReturnArray: "ColorSelected",
    propss: {
      Data: "",
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "ColorSelected"),
      SearchWords: "",
      ShowEdit: true,
      showcustom: true,
    }
  },
  {
    title: "SMS Feedback Messages",
    message: "SMS Feedback Messages",
    LoadComponenet: "SMSMessagesBack",
    id: "SMSMessagesBack",
    AddBox: (e) => props.AddBox(e),
    ShowAdd: false,
    DetailComponent: "",
    Additem: (add) => props.Additem(add),
    ReturnArray: "SMSMessagesBack",
    propss: {
      Data: "",
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
      // SelectedDataReturn: (e) => props.SaveSelection(e, "SMSMessagesBack"),
      SearchWords: "",
      ShowEdit: true,
      showcustom: true,
    }
  },
];

export const EmailTriggerBlocks = (props) => [

    {
        title: "Email Trigger",
        message: "",
        LoadComponenet: "CreateEmailTrigger",
        id: "CreateEmailTrigger",
        AddBox: (e) => props.AddBox(e),
        ShowAdd: false,
        DetailComponent: "CreateEmailTrigger",
        Additem: (add) => props.Additem(add),
        ReturnArray: "EmailTrigger",
        propss: {
          //  Data: props.ContractorData,
            CompanyId: props.CompanyId,
            CompanyName: props.CompanyName,
            // SelectedDataReturn: (e) => props.SaveSelection(e, "EmailTrigger"),
            SearchWords: "",
            ShowEdit: true,

        }
    },

    {
        title: "Select Assets",
        message: "",
        LoadComponenet: "PreSelectEmail",
        id: "PreSelectAssets",
        AddBox: (e) => props.AddBox(e),
        ShowAdd: false,
        DetailComponent: "PreSelectEmail",
        Additem: (add) => props.Additem(add),
        ReturnArray: "PreSelectAssets",
        propss: {
            Data: "",
            CompanyId: props.CompanyId,
            DetailComponent: "PreSelectEmail",
            CompanyName: props.CompanyName,
            SearchWords: "",
            ShowEdit: true,
            title: `Select Location`,
            Category: "Location",
        }
    },
    {
        title: "Default Priority",
        message: "",
        LoadComponenet: "PrioritySelection",
        id: "PrioritySelection",
        AddBox: (e) => props.AddBox(e),
        ShowAdd: false,
        DetailComponent: "SMSAddEdit",
        Additem: (add) => props.Additem(add),
        ReturnArray: "StepReturn",
        propss: {
            Data: "",
            CompanyId: props.CompanyId,
            CompanyName: props.CompanyName,
            Priority: props.Priority,
            // SelectedDataReturn: (e) => props.SaveSelection(e, "Priority"),
            SearchWords: "",
            ShowEdit: true,

        }
    },

    {
        title: "Time Zone",
        message: "",
        LoadComponenet: "TimeZonePreSelect",
        id: "TimeZone",
        AddBox: (e) => props.AddBox(e),
        ShowAdd: false,
        DetailComponent: "TimeZonePreSelect",
        Additem: (add) => props.Additem(add),
        ReturnArray: "TimeZone",
        propss: {
            Data: "",
            CompanyId: props.CompanyId,
            CompanyName: props.CompanyName,
           // Priority: props.Priority,
            // SelectedDataReturn: (e) => props.SaveSelection(e, "Priority"),
            SearchWords: "",
            ShowEdit: true,

        }
    },
    
    {
        title: "Silence Notification",
        message: "Pause / Silence notificaion to avoid repeat notification triggers in short span",
        LoadComponenet: "SilenceNotification",
        id: "SilenceNotification",
        AddBox: (e) => props.AddBox(e),
        ShowAdd: false,
        DetailComponent: "SilenceNotification",
        Additem: (add) => props.Additem(add),
        ReturnArray: "Silence",
        propss: {
            Data: "",
            CompanyId: props.CompanyId,
            CompanyName: props.CompanyName,
            Priority: props.Priority,
            // SelectedDataReturn: (e) => props.SaveSelection(e, "Silence"),
            SearchWords: "",
            ShowEdit: true,

        }
    },
    
    {
        title: "Show Progress Form",
        message: "",
        LoadComponenet: "ProgressList",
        id: "ProgressList",
        AddBox: (e) => props.AddBox(e),
        ShowAdd: false,
        DetailComponent: "ProgressSelectSteps",
        Additem: (add) => props.Additem(add),
        ReturnArray: "ProgressReturn",
        propss: {
            Data: "",
            CompanyId: props.CompanyId,
            DetailComponent: "ProgressSelectSteps",
            CompanyName: props.CompanyName,
            // SelectedDataReturn: (e) => props.SaveSelection(e, "ProgressReturn"),
            SearchWords: "",
            ShowEdit: true,

        }
    },

    
    //{
    //    title: `Select ${props.ContractorName} Notification Policies`,
    //    message: "this is the message for this module 2",
    //    LoadComponenet: "ESCSelectContractor",
    //    id: "ESCSelectContractor",
    //    AddBox: (e) => props.AddBox(e),
    //    ShowAdd: false,
    //    DetailComponent: "ESCSetupPage",
    //    Additem: (add) => props.Additem(add),
    //    ReturnArray: "ESCReturn",
    //    propss: {
    //        Data: props.ContractorData,
    //        CompanyId: props.CompanyId,
    //        CompanyName: props.CompanyName,
    //        // SelectedDataReturn: (e) => props.SaveSelection(e, "ESCContractor"),
    //        SearchWords: "",
    //        ShowEdit: true,
    //        showcustom: true,
    //    },
    //},

    {
        title: `Select ${props.ContractorName} Notification per shift`,
        message: "",
        LoadComponenet: "ESCContractorDefault",
        id: "ESCSelectContractor",
        AddBox: (e) => props.AddBox(e),
        ShowAdd: false,
        DetailComponent: "ESCSetupPage",
        Additem: (add) => props.Additem(add),
        ReturnArray: "ESCReturn",
        propss: {
         //   Data: props.ContractorData,
            CompanyId: props.CompanyId,
            CompanyName: props.CompanyName,
            // SelectedDataReturn: (e) => props.SaveSelection(e, "ESCContractor"),
            SearchWords: "",
            ShowEdit: true,
            showcustom: true,
        },
    },

    {
        title: `Select ${props.CompanyName} Notification Policies`,
        message: "this is the message for this module 2",
        LoadComponenet: "ESCSelectCompany",
        id: "ESCSelectCompany",
        AddBox: (e) => props.AddBox(e),
        ShowAdd: false,
        DetailComponent: "ESCSetupPage",
        Additem: (add) => props.Additem(add),
        ReturnArray: "ESCReturn",
        propss: {
            Data: props.CompanyData,
            CompanyId: props.CompanyId,
            CompanyName: props.CompanyName,
            // SelectedDataReturn: (e) => props.SaveSelection(e, "ESCCompany"),
            SearchWords: "",
            ShowEdit: true,
            showcustom: true,
        },
    },
    {
        title: "Special Instructions",
        message: "This section is to list any special instructions, policy, or procedures that the service tech needs to know",
        LoadComponenet: "SpecialInstructions",
        id: "SpecialInstructions",
        AddBox: (e) => props.AddBox(e),
        ShowAdd: false,
        DetailComponent: "",
        Additem: (add) => props.Additem(add),
        ReturnArray: "SpecialInstructions",
        propss: {
            Data: "",
            CompanyId: props.CompanyId,
            CompanyName: props.CompanyName,
            // SelectedDataReturn: (e) => props.SaveSelection(e, "SpecialInstructions"),
            SearchWords: "",
            ShowEdit: true,
            showcustom: true,
        }
    },
    {
        title: "Select Box Color",
        message: "this is the message for this module 2",
        LoadComponenet: "ColorSelection",
        id: "ColorSelection",
        AddBox: (e) => props.AddBox(e),
        ShowAdd: false,
        DetailComponent: "",
        Additem: (add) => props.Additem(add),
        ReturnArray: "ColorSelected",
        propss: {
            Data: "",
            CompanyId: props.CompanyId,
            CompanyName: props.CompanyName,
            // SelectedDataReturn: (e) => props.SaveSelection(e, "ColorSelected"),
            SearchWords: "",
            ShowEdit: true,
            showcustom: true,
        }
    },
    //{
    //    title: "SMS Feedback Messages",
    //    message: "SMS Feedback Messages",
    //    LoadComponenet: "SMSMessagesBack",
    //    id: "SMSMessagesBack",
    //    AddBox: (e) => props.AddBox(e),
    //    ShowAdd: false,
    //    DetailComponent: "",
    //    Additem: (add) => props.Additem(add),
    //    ReturnArray: "SMSMessagesBack",
    //    propss: {
    //        Data: "",
    //        CompanyId: props.CompanyId,
    //        CompanyName: props.CompanyName,
    //        // SelectedDataReturn: (e) => props.SaveSelection(e, "SMSMessagesBack"),
    //        SearchWords: "",
    //        ShowEdit: true,
    //        showcustom: true,
    //    }
    //},
];
