export const TotalList = [
  {
    key: "title",
    label: "Title",
    labelPermanent: "Title",
    include: false,
    Preset: false,
    ModuleTitle: "Title",
    message: "Please enter heading / title",
    LoadComponenet: "Title",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "title",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "description",
    label: "Description",
    include: false,
    Preset: false,
    labelPermanent: "Description",
    ModuleTitle: "Description",
    message: "Please enter description and details",
    LoadComponenet: "Description",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "description",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },

  {
    key: "Trade",
    label: "Service Specielty Trade",
    labelPermanent: "Service Specielty Trade",
    include: false,
    Preset: true,
    ModuleTitle: "Trade",
    message: "Please select Service Specielty Trade",
    LoadComponenet: "ShowTradeOption",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "ShowTradeOption",
    DataSendArrayName: "TradeSelected",
    disabled: false,
    Category: "",
    VariableInput: "",
  },


  {
    key: "Location",
    label: "Location",
    labelPermanent: "Location",
    include: false,
    Preset: true,
    ModuleTitle: "Location",
    message: "Location of the work",
    LoadComponenet: "Autocomplete",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedLocation",
    DataSendArrayName: "",
    disabled: false,
    Category: "Location",
    VariableInput: "",
  },

  {
    key: "DateRange",
    label: "Dates",
    labelPermanent: "Dates",
    ModuleTitle: "Dates",
    Preset: false,
    include: false,
    message: "Select dates for service",
    LoadComponenet: "DateRange",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "DateRange",
    DataSendArrayName: "DateRange",
    disabled: false,
    Category: "Date",
    VariableInput: "",
  },

  //{
  //  key: "TimeRange",
  //  label: "Select Time",
  //  ModuleTitle: "TimeRange",
  //  message: "Select Time for service",
  //  LoadComponenet: "TimeRange",
  //  LoadComponenet2: "",
  //  LoadComponenet3: "",
  //  Trigger1: false,
  //  Trigger2: false,
  //  Trigger3: false,
  //  required: false,
  //  DataReceivedArrayName: "TimeRange",
  //  DataSendArrayName: "",
  //  disabled: false,
  //  Category: "Time",
  //  VariableInput: "",
  //},

  {
    key: "Upload",
    label: "Picture or documents",
    ModuleTitle: "Upload",
    labelPermanent: "Picture or documents",
    Preset: false,
    include: false,
    message: "Provide picture or documents",
    LoadComponenet: "Upload",
    LoadComponenet2: "ShowImage",
    LoadComponenet3: "ShowImage",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedDocuments",
    DataSendArrayName: "SelectedDocuments",
    disabled: false,
    Category: "Upload",
    VariableInput: "",
  },

  {
    key: "PrivateMessage",
    label: "Private Message",
    labelPermanent: "Private Message",
    Preset: false,
    ModuleTitle: "Private Message",
    include: false,
    message: "Add Private Message",
    LoadComponenet: "PrivateMessage",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: true, // trigger=true to activate text box for private message
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "PrivateMessage",
    DataSendArrayName: "",
    disabled: false,
    Category: "ExtraNotification",
    VariableInput: "",
  },

  {
    key: "ExtraNotification",
    label: "Extra Notification",
    labelPermanent: "Extra Notification",
    Preset: true,
    include: false,
    ModuleTitle: "Extra Notification",
    message: "The system will automatically notify the on-call personal.  Please use this step to add other personal that need to receive copy of this service request",
    LoadComponenet: "ExtraNotification",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false, // trigger=true to activate text box for private message
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "ExtraNotification",
    DataSendArrayName: "",
    disabled: false,
    Category: "ExtraNotification",
    VariableInput: "",
  },
];

export const schedule = [
  {
    key: "DateRange",
    label: "Dates",
    labelpermanent: "Dates",
    ModuleTitle: "Dates",
    message: "Select dates for service",
    LoadComponenet: "DateRange",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "DateRange",
    DataSendArrayName: "DateRange",
    disabled: false,
    Category: "Date",
    VariableInput: "",
  },
  {
    key: "title",
    label: "Title",
    labelpermanent: "Title",
    ModuleTitle: "Title",
    message: "Please enter heading / title",
    LoadComponenet: "Title",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "title",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "description",
    label: "Description",
    labelpermanent: "Description",
    ModuleTitle: "Description",
    message: "Please enter description and details",
    LoadComponenet: "Description",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "description",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "Trade",
    label: "Service Specielty Trade",
    ModuleTitle: "Trade",
    message: "Please select Service Specielty Trade",
    LoadComponenet: "ShowTradeOption",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "ShowTradeOption",
    DataSendArrayName: "TradeSelected",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "Location",
    label: "Location",
    ModuleTitle: "Location",
    message: "Location of the work",
    LoadComponenet: "Autocomplete",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedLocation",
    DataSendArrayName: "",
    disabled: false,
    Category: "Location",
    VariableInput: "",
  },
];

export const emergency = [
  {
    key: "title",
    label: "Title",
    ModuleTitle: "Title",
    message: "Please enter heading / title",
    LoadComponenet: "Title",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "title",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "description",
    label: "Description",
    labelpermanent: "Description",
    ModuleTitle: "Description",
    message: "Please enter description and details",
    LoadComponenet: "Description",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "description",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },

  {
    key: "Trade",
    label: "Service Specielty Trade",
    ModuleTitle: "Trade",
    message: "Please select Service Specielty Trade",
    LoadComponenet: "ShowTradeOption",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "ShowTradeOption",
    DataSendArrayName: "TradeSelected",
    disabled: false,
    Category: "",
    VariableInput: "",
  },


  {
    key: "Location",
    label: "Location",
    ModuleTitle: "Location",
    message: "Location of the work",
    LoadComponenet: "Autocomplete",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedLocation",
    DataSendArrayName: "",
    disabled: false,
    Category: "Location",
    VariableInput: "",
  },

  {
    key: "DateRange",
    label: "Dates",
    ModuleTitle: "Dates",
    message: "Select dates for service",
    LoadComponenet: "DateRange",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "DateRange",
    DataSendArrayName: "DateRange",
    disabled: false,
    Category: "Date",
    VariableInput: "",
  },

  //{
  //  key: "TimeRange",
  //  label: "Select Time",
  //  ModuleTitle: "TimeRange",
  //  message: "Select Time for service",
  //  LoadComponenet: "TimeRange",
  //  LoadComponenet2: "",
  //  LoadComponenet3: "",
  //  Trigger1: false,
  //  Trigger2: false,
  //  Trigger3: false,
  //  required: false,
  //  DataReceivedArrayName: "TimeRange",
  //  DataSendArrayName: "",
  //  disabled: false,
  //  Category: "Time",
  //  VariableInput: "",
  //},

  {
    key: "Upload",
    label: "Picture or documents",
    ModuleTitle: "Upload",
    message: "Provide picture or documents",
    LoadComponenet: "Upload",
    LoadComponenet2: "ShowImage",
    LoadComponenet3: "ShowImage",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedDocuments",
    DataSendArrayName: "SelectedDocuments",
    disabled: false,
    Category: "Upload",
    VariableInput: "",
  },

  {
    key: "ExtraNotification",
    label: "Private Message",
    ModuleTitle: "Private Message",
    message: "Add Private Message",
    LoadComponenet: "ExtraNotification",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: true, // trigger=true to activate text box for private message
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "PrivateMessage",
    DataSendArrayName: "",
    disabled: false,
    Category: "ExtraNotification",
    VariableInput: "",
  },

  {
    key: "ExtraNotification",
    label: "Extra Notification",
    ModuleTitle: "Extra Notification",
    message: "The system will automatically notify the on-call personal.  Please use this step to add other personal that need to receive copy of this service request",
    LoadComponenet: "ExtraNotification",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false, // trigger=true to activate text box for private message
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "ExtraNotification",
    DataSendArrayName: "",
    disabled: false,
    Category: "ExtraNotification",
    VariableInput: "",
  },
];

export const todo = [
  {
    key: "title",
    label: "Title",
    ModuleTitle: "Title",
    message: "Please enter heading / title",
    LoadComponenet: "Title",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: true,
    DataReceivedArrayName: "title",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "Upload",
    label: "Picture or documents",
    ModuleTitle: "Upload",
    message: "Provide picture or documents",
    LoadComponenet: "Upload",
    LoadComponenet2: "ShowImage",
    LoadComponenet3: "ShowImage",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedDocuments",
    DataSendArrayName: "SelectedDocuments",
    disabled: false,
    Category: "Upload",
    VariableInput: "",
  },
  {
    key: "description",
    label: "Description",
    ModuleTitle: "Description",
    labelpermanent: "Description",
    message: "Please enter description and details",
    LoadComponenet: "Description",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "description",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "Trade",
    label: "Service Specielty Trade",
    ModuleTitle: "Trade",
    message: "Please select Service Specielty Trade",
    LoadComponenet: "ShowTradeOption",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "ShowTradeOption",
    DataSendArrayName: "TradeSelected",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
  {
    key: "Equipment",
    label: "Equipment",
    ModuleTitle: "Equipment",
    message: "Please select the Equipment",
    LoadComponenet: "Autocomplete",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedEquipment",
    DataSendArrayName: "",
    disabled: false,
    Category: "Equipment",
    VariableInput: "",
  },
  {
    key: "Location",
    label: "Location",
    ModuleTitle: "Location",
    message: "Location of the work",
    LoadComponenet: "Autocomplete",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedLocation",
    DataSendArrayName: "",
    disabled: false,
    Category: "Location",
    VariableInput: "",
  },
];

export const quote = [
  {
    key: "title",
    label: "Title",
    ModuleTitle: "Title",
    message: "Please enter heading / title",
    LoadComponenet: "Title",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: true,
    DataReceivedArrayName: "title",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },

  {
    key: "Upload",
    label: "Picture or documents",
    ModuleTitle: "Upload",
    message: "Provide picture or documents",
    LoadComponenet: "Upload",
    LoadComponenet2: "ShowImage",
    LoadComponenet3: "ShowImage",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "SelectedDocuments",
    DataSendArrayName: "SelectedDocuments",
    disabled: false,
    Category: "Upload",
    VariableInput: "",
  },

  {
    key: "description",
    label: "Description",
    ModuleTitle: "Description",
    labelpermanent: "Description",
    message: "Please enter description and details",
    LoadComponenet: "Description",
    LoadComponenet2: "",
    LoadComponenet3: "",
    Trigger1: false,
    Trigger2: false,
    Trigger3: false,
    required: false,
    DataReceivedArrayName: "description",
    DataSendArrayName: "",
    disabled: false,
    Category: "",
    VariableInput: "",
  },
];

export const SMSQuestions = [

  {
    key: "Location",
    Category: "Location",
    label: "Please provide location, room, bldg, etc. where service is needed?",
    field1: "Please provide location, room, bldg, etc. where service is needed?",
    PreSelect: false,
    include: false,
    VariableInput: false,
    keywords: {
      words: "",
      ESC: []
    }
  },


  {
    key: "Equipment",
    Category: "Equipment",
    label: "Please provide the name/tag of the equipment or system that need service. You can include picture of the equipment nameplate",
    field1: "Please provide the name/tag of the equipment or system that need service. You can include picture of the equipment nameplate",
    include: false,
    PreSelect: false,
    VariableInput: false,
    keywords: {
      words: "",
      ESC: []
    }
  },

  {
    key: "Problem",
    Category: "Problem",
    label: "Please include pictures or a short video of the problem",
    field1: "Please include pictures or a short video of the problem",
    include: false,
    PreSelect: false,
    VariableInput: false,
    keywords: {
      words: "",
      ESC: []
    }
  },
  //{
  //  key: "Warratny",
  //  Category: "Warratny",
  //  label: "Warranty or new service issue?",
  //  field1: "Warranty or new service issue?",
  //  PreSelect: false,
  //  include: false,
  //  VariableInput: false,
  //  keywords: {
  //    words: "",
  //    ESC: []
  //  }
  //},
  
  
];

export const ProgressQuestions = [

  {
    key: "parts",
    label: "Parts",
    field1: "Parts",
    labelTop: "Parts",
    VariableInput: false,
    include: true,
    showdate: true,
    date: "",
    datelabel: "Parts expected date",
    showoptions: true,
    options: "Ordered, No Parts Needed, On Hold, Back Ordered, In Stock",
    optionlabel: "Parts Order",
    optionsSelected: "",
    Notes: "",
    stepcomplete: false,
    required: true
  },
  {
    key: "schedule",
    label: "Personal Scheduled",
    field1: "Personal Scheduled",
    labelTop: "Personal Scheduled",
    include: true,
    VariableInput: false,
    showdate: true,
    date: "",
    datelabel: "Work start date",
    showoptions: false,
    options: "",
    optionlabel: "",
    optionsSelected: "",
    Notes: "",
    stepcomplete: false,
    required: true
  },
  {
    key: "workcompleted",
    label: "Work Completed",
    field1: "Work Completed",
    labelTop: "Work Completed",
    include: true,
    VariableInput: false,
    showdate: true,
    date: "",
    datelabel: "Work Completed",
    showoptions: false,
    options: "",
    optionlabel: "",
    optionsSelected: "",
    Notes: "",
    stepcomplete: false,
    required: true
  },
  {
    key: "workaccepted",
    label: "Work Accepted",
    field1: "Work Accepted",
    labelTop: "Work Accepted",
    include: true,
    VariableInput: false,
    showdate: true,
    date: "",
    datelabel: "Accepted Date",
    showoptions: false,
    options: "",
    optionlabel: "",
    optionsSelected: "",
    Notes: "",
    stepcomplete: false,
    required: true
  },

];
