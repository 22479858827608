import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SRColumn from '../List/SRColumn'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

let ProgressArray = [
    {
        label: "Service Requested",
        VariableSearch: "Progress",
        ExpectedValue1: 0,
        ExpectedValue2: 1,
        Data: []
    },
    {
        label: "In Progress",
        VariableSearch: "Progress",
        ExpectedValue1: 20,
        ExpectedValue2: 73,
        Data: []
    },
    {
        label: "Completed/Pending Acceptance",
        VariableSearch: "Progress",
        ExpectedValue1: 74,
        ExpectedValue2: 100,
        Data: []
    },
];

let CompletedArray = [
    
    {
        label: "Completed & Accepted",
        VariableSearch: "Progress",
        ExpectedValue1: 101,
        ExpectedValue2: 110,
        Data: []
    },
];

let ApprovalArray = [
    {
        label: "Approved",
        VariableSearch: "ManagerApprovalStatus",
        ExpectedValue1: "Approved",
        Data: []
    },
    {
        label: "Pending",
        VariableSearch: "ManagerApprovalStatus",
        ExpectedValue1: "Pending",
        Data: []
    },
    {
        label: "Rejected",
        VariableSearch: "ManagerApprovalStatus",
        ExpectedValue1: "Rejected",
        Data: []
    },
];

export default function ColumnsGrid(props) {

    const initialState = {
        ColumnTitle1: "Column 1",
        Data1: [],
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        if (props && props.Data) {
            setState(prevState => ({
                ...prevState,
                Data: props.Data,
                Sort: props.Sort
            }));
        }

        //if (props.Sort === "Approval") {
        //    ProcessApproval(props.Data, props.Sort);
        //} else {
        //    ProcessProgress(props.Data, props.Sort);
        //}

        switch (props.Sort) {
            case "Approval":
                ProcessApproval(props.Data, props.Sort);
                break;
            case "Progress":
                ProcessProgress(props.Data, props.Sort);
                break;
            case "Completed":
                CompletedList(props.Data, props.Sort);
                break;
        }

    }, [props.Data, props.Sort]);

    const ProcessProgress = (Data, Sort) => {

        let TempArray = [];
        let NewArray = ProgressArray;

        ProgressArray.map((each, indd) => {
           // console.log("ProgressArray", each.VariableSearch, each.ExpectedValue1, each.ExpectedValue2)
            TempArray = Data.filter(x => (
                x[each.VariableSearch] >= each.ExpectedValue1 &&
                x[each.VariableSearch] <= each.ExpectedValue2)
            )

            NewArray[indd] = {
                ...NewArray[indd],
                Data: TempArray
            }

            TempArray = [];
        })

        setState(prevState => ({
            ...prevState,
            NewArray: NewArray,
            Sort: Sort
        }));

    }

    const CompletedList = (Data, Sort) => {

        let TempArray = [];
        let NewArray = CompletedArray;

        CompletedArray.map((each, indd) => {
             console.log("ProgressArray", each.VariableSearch, each.ExpectedValue1, each.ExpectedValue2)
            TempArray = Data.filter(x => (
                x[each.VariableSearch] >= each.ExpectedValue1 &&
                x[each.VariableSearch] <= each.ExpectedValue2)
            )

            NewArray[indd] = {
                ...NewArray[indd],
                Data: TempArray
            }

            TempArray = [];
        })

        setState(prevState => ({
            ...prevState,
            NewArray: NewArray,
            Sort: Sort
        }));

    }

    const ProcessApproval = (Data, Sort) => {

        let TempArray = [];
        let NewArray = ApprovalArray;

        ApprovalArray.map((each, indd) => {

            TempArray = Data.filter(x => x[each.VariableSearch] === each.ExpectedValue1)

            NewArray[indd] = {
                ...NewArray[indd],
                Data: TempArray
            }

            TempArray = [];
        })

        setState(prevState => ({
            ...prevState,
            NewArray: NewArray,
            Sort: Sort
        }));

    }

    console.log("SRTest.Three.props", props);
    console.log("SRTest.Three.state", state);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {state.NewArray && state.NewArray.map((each, indd) =>
                   // each.Data.length > 0 &&
                    (
                        <Grid item xs={12} md={4} key={"each" + indd}>
                            <SRColumn
                                ColumnTitle={each.label}
                                Data={each.Data}
                                key={"each2" + indd}
                            />
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
}
