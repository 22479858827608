import React, { useState, useEffect } from 'react';
import {
    NewPageId,
    Pk1SingelItem,
} from '../utils/CommonGraphql';
//import { useSelector } from 'react-redux';
import {
    Grid,
    Backdrop, Box,
    CircularProgress,
} from '@mui/material';
import ListItems from '../List/ListItems';
import ShowDetailsText from '../Add/ShowDetailsText';

const UUid = NewPageId("shwdtl");


export default function SimpleCard(props) {

    //const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    //const [expanded, setExpanded] = React.useState(false);
    //const ContractorData = useSelector(state => state.ContractorData);
    const [loading, SetLoading] = useState(false);
    //const UserData = useSelector(state => state.UserData);
    //const cid = useSelector(state => state.cid);

    const initialState = {
        Data: [],
        id: UUid
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log("ShowDetailsPkSk.props1", props)

        if (props.ItemData && props.ItemData.AssetPkSk && props.ItemData.AssetPkSk.pk1) {

            setState(prevState => ({
                ...prevState,
                Category: props.ItemData.Category,
                AssetId: props.ItemData.AssetPkSk.sk1
            }))

            if (props.ItemData.AssetPkSk && props.ItemData.AssetPkSk.pk1) {
                GetDatafromDB(props.ItemData.AssetPkSk.pk1, props.ItemData.AssetPkSk.sk1);
            }
        }

        if (props.ItemData && props.ItemData.pk1 && props.ItemData.sk1) {

            setState(prevState => ({
                ...prevState,
                Category: props.ItemData.Category,
                AssetId: props.ItemData.sk1,
                Data: props.ItemData
            }))

            
        }

    }, [props.ItemData])

    useEffect(() => {

        console.log("ShowDetailsPkSk.props2", props)

        if (props.ActiveKey) {

            setState(prevState => ({
                ...prevState,
                Category: props.ActiveCategory,
                AssetId: props.ActiveKey,
            }))

            if (props.CompanyId && props.ActiveKey) {
                GetDatafromDB(props.CompanyId, props.ActiveKey);
            }
        }

    }, [props.ActiveKey])

    const GetDatafromDB = async (pk, sk) => {
        SetLoading(true);

        let NewData2 = await Pk1SingelItem(pk, sk);
        console.log("ShowDetailsPkSk.NewData2", NewData2)
        let NewData = await NewData2;
        console.log("ShowDetailsPkSk.NewData", NewData)

        if (NewData) {
            let Data = JSON.parse(NewData.dataJSON);
            console.log("ShowDetailsPkSk.NewData3", Data)
            setState(prevState => ({
                ...prevState,
                Data: Data
            }))
        }

        SetLoading(false);
    }


    //const CloseDrawer = () => {
    //    //console.log("CloseDrawer", e)
    //    SetDeawerOpen(false)
    //}

    //const handleChange = (event, name) => {
    //    setState({
    //        ...state,
    //        [name]: event.target.checked,
    //    });
    //};


    //const handleInclude = (data) => {

    //  console.log("ContactList.data", data)

    //  let ContactList = state.ContactList;
    //  let findindex = ContactList.findIndex(x => x.key === data.key);

    //  if (findindex && findindex > -1) {
    //    ContactList[findindex] = data;
    //  } else {
    //    // if key does not exist, then add as new
    //    ContactList = [...state.ContactList, data];
    //  }

    //  console.log("ContactList.handleInclude", ContactList)

    //  setState(prevState => ({
    //    ...prevState,
    //    ContactList: ContactList
    //  }));
    //  }


    console.log("ShowDetailsPkSk.state", state)

    return (
        <Box mt={2}>
            <Grid container spacing={2} >

                {state.Data && (
                    <Grid item xs={12} lg={6}>
                        <ShowDetailsText
                            title={state.field1}
                            Category={state.Category}
                            ShowEditButton={false}
                            PreviewEdit={false}
                            Data={state.Data}
                        />
                    </Grid>
                )}

                {state.AssetId && (
                    <Grid item xs={12} lg={6}>
                        <ListItems
                            title="Related Documents"
                            Category="AssetDocuments"
                            AssetType={state.Category}
                            CompanyId={state.AssetId}
                            id={state.id + "doc"}
                            DetailLink={true}
                            AllowAdd={false}
                            AllowEdit={false}
                            AllowDelete={false}
                            DetailComponent="ShowDetailsText"
                        />
                    </Grid>
                )}

                {state.AssetId && (
                    <Grid item xs={12} lg={6}>
                        <ListItems
                            CompanyId={state.AssetId}
                            id={state.id + "hist"}
                            title="History"
                            DetailLink={true}
                            AllowAdd={false}
                            AllowEdit={false}
                            AllowDelete={false}
                            DetailComponent="SRDetailsPkSk"
                            Category="AssetHistory"
                            AssetType={state.Category}

                        />
                    </Grid>
                )}

               

            </Grid>
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/*    
             *    
             *    
             *    
            DrawerTitle: "Document Details",
            DetailComponent: "ShowDetailsText",
            ItemData: add,
            DrawerKey: NewPageId("DrawerKey"),
            NewCategory: "ShowDetails",
             *    
             *    
             *    
            
            <DrawerShow
                LoadComponenet={state.NewLoadComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={state.Category}
                //LoadComponenet="ItemAddPage"
                propss={{
                    Category: "Person",
                    CloseDrawer: (e) => CloseDrawer(e),
                    //step: state.DetailsProps,
                    //  handleInclude: (e) => handleInclude(e),
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
*/}
        </Box>
    );
}
