import React, { useState, useEffect } from 'react';
//import { makeStyles } from '@mui/material/styles';
//import Paper from '@mui/material/Paper';
import { Grid, Typography, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formatDate } from '../utils/DateTime';
//import moment from 'moment';
//import DateFnsUtils from '@date-io/date-fns';
//import DateFnsAdapter from '@date-io/date-fns';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';

//const dateFns = new DateFnsAdapter();

//import Quill from '../components/quill'
//import InputBase from '@mui/material/InputBase';
//import Divider from '@mui/material/Divider';
//import IconButton from '@mui/material/IconButton';
//import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
//import DeleteIcon from '@mui/icons-material/Delete';

//const useStyles = makeStyles((theme) => ({
//  root: {
//    padding: '2px 4px',
//    display: 'flex',
//    alignItems: 'center',
//    // width: 400,
//  },
//  input: {
//    marginLeft: theme.spacing(1),
//    flex: 1,
//  },
//  iconButton: {
//    padding: 10,
//  },
//  divider: {
//    height: 28,
//    margin: 4,
//  },
//}));

//var today = new Date();
//var dd = String(today.getDate()).padStart(2, '0');
//var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
//var yyyy = today.getFullYear();

//let startdate = mm + '/' + dd + '/' + yyyy;

const DateFormat = 'MM/dd/yyyy';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
  formControl: {
    // margin: theme.spacing(1),
  },
}));

export default function CustomizedInputBase(props) {
  const classes = useStyles();
  const initialState = {
    StartTime: "07:00",
    EndTime: "16:00",
    StartDate: new Date(),
    EndDate: new Date(),
  };

  const [state, setState] = useState(initialState);
  //const [selectedDate, handleDateChange] = useState(new Date());

  useEffect(() => {
    let DateRange = [
      {
        label: "Start Date",
        VariableName: "StartDate",
        value: state.StartDate,
        Message: ""
      },
      {
        label: "End Date",
        VariableName: "EndDate",
        value: state.EndDate,
        Message: ""
      },
      //{
      //  label: "Start Time",
      //  VariableName: "StartTime",
      //  value: state.StartTime,
      //  Message: ""
      //},
      //{
      //  label: "End Time",
      //  VariableName: "EndTime",
      //  value: state.EndTime,
      //  Message: state.Endtimemessage,
      //}
    ];

    if (props.SelectedValues) {
      props.SelectedValues(DateRange);
    }

    if (props.StartDate) {
      props.StartDate(formatDate(state.StartDate))
    }

    if (props.EndDate) {
      props.EndDate(formatDate(state.EndDate))
    }
    

  }, [state.StartDate, state.EndDate])

 
  useEffect(() => {
    props.DataSent.map(item => {
      setState((prevState) => ({
        ...prevState,
        [item.VariableName]: item.value,
      })) 
    })
    console.log("DateRange.props.DataSent", props.DataSent)
  }, [])

  const SelectedValues = (name, item) => {   
    console.log("DateRange", name, item)
    setState((prevState) => ({
      ...prevState,
      [name]: item,
    }))

    //if start date > end date = change the end date
    if (name === "StartDate") {
      if (new Date(item) > new Date(state.EndDate)) {
        setState((prevState) => ({
          ...prevState,
          EndDate: item,
        }))
      }
    }

  }

  //const EndTimeCheck = (e) => {
  //  let Endtimemessage = ""
  //  if (e < state.StartTime) {
  //    Endtimemessage = "Next Day";
  //  }
  //  setState(prevState => ({ ...prevState, EndTime: e, EndTimeMessage: Endtimemessage }));
  //}

  //const StartTimeCheck = (e) => {
  //  let Endtimemessage = ""
  //  if (e > state.EndTime) {
  //    Endtimemessage = "Next Day";
  //  }
  //  setState(prevState => ({ ...prevState, StartTime: e, EndTimeMessage: Endtimemessage }));
  //}

  console.log("DateRange.selectedDate", props )

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={2} >
        {props.ShowStartDate && (
          <>
        <Grid
          key="1"
          item
          xs={4}>
          <Typography variant="h5" component="h2">
            Start Date:
            </Typography>

        </Grid>
        <Grid
          key="2"
          item
          xs={8}>
              
              <DatePicker
                value={state.StartDate}
                label="Start Date"
                onChange={item => SelectedValues("StartDate", item)}
                renderInput={(props) => (
                  <TextField {...props} />
                )}
              />

            </Grid>
            </>
        )}
        {props.ShowEndDate === "DateRange" && (
          <>
        <Grid
          key="3"
          item
          xs={4}>
          <Typography variant="h5" component="h2">
            End Date:
            </Typography>
        </Grid>
        <Grid
          key="4"
          item
          xs={8}>
              
              <DatePicker
                label="End Date"
                value={state.EndDate}
                fullWidth
                onChange={item => SelectedValues("EndDate", item)}
                renderInput={(props) => (
                  <TextField {...props} />
                )}
              />

            </Grid>        
            </>
        )}
      </Grid>
    </LocalizationProvider>
  );
}
